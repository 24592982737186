import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import Loading from '~/components/Loading';
// import Copyright from '~/components/Copyright';

import { useLoading } from '~/hooks/loading';

import systemTheme from '~/styles/theme';

import { useStyles } from './styles';

export default function Layout({ children }) {
  const classes = useStyles();

  const { loadingShowed } = useLoading();

  const START_PAGE = window.location.pathname === '/';

  return (
    <ThemeProvider theme={systemTheme()}>
      <Grid container component="main" className={classes.root}>
        <Grid item xs={1} sm={1} md={(START_PAGE && 3) || 4} />
        <Grid item xs={10} sm={10} md={4} className={classes.content}>
          {loadingShowed && <Loading />}
          {children}
          {/* <Copyright /> */}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};
