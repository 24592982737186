import { etapas } from './etapas';
import { fases } from './fases';
import { niveis } from './niveis';
import { user } from './user';

export default {
  etapas,
  fases,
  niveis,
  user,
};
