export const niveis = [
  {
    id: 1,
    descricao: 'Iniciante',
    concordancia: 0.4,
    acuracia: 70,
    sensibilidade: 70,
    especificidade: 70,
  },
  {
    id: 2,
    description: 'Intermediário',
    concordancia: 0.6,
    acuracia: 80,
    sensibilidade: null,
    especificidade: null,
  },
  {
    id: 3,
    description: 'Avançado',
    concordancia: 0.8,
    acuracia: 90,
    sensibilidade: null,
    especificidade: null,
  },
];
