import React from 'react';
import { Typography, Button } from '@material-ui/core';

import { useStyles } from './styles';

import history from '~/services/history';

export default function Error() {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      <Typography component="p" variant="h5" color="secondary" text>
        404
      </Typography>
      <Typography component="p" variant="h1">
        Ops...
      </Typography>
      <Typography component="p" variant="h6" className={classes.message}>
        Página não encontrada.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.push('/')}
        size="large"
      >
        Voltar
      </Button>
    </div>
  );
}
