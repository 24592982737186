import VozE1157 from './v_e_pac_1157.wav';
import VozE477 from './v_e_pac_477.wav';
import VozE757 from './v_e_pac_757.wav';
import VozE768 from './v_e_pac_768.wav';
import VozE1065 from './v_e_pac_1065.wav';
import VozE1139 from './v_e_pac_1139.wav';
import VozE1154 from './v_e_pac_1154.wav';
import VozE1246 from './v_e_pac_1246.wav';
import VozE317 from './v_e_pac_317.wav';
import VozE488 from './v_e_pac_488.wav';
import VozE565 from './v_e_pac_565.wav';
import VozE624 from './v_e_pac_624.wav';
import VozE634 from './v_e_pac_634.wav';
import VozE657 from './v_e_pac_657.wav';
import VozE670 from './v_e_pac_670.wav';
import VozE677 from './v_e_pac_677.wav';
import VozE689 from './v_e_pac_689.wav';
import VozE737 from './v_e_pac_737.wav';
import VozE746 from './v_e_pac_746.wav';
import VozE758 from './v_e_pac_758.wav';
import VozE770 from './v_e_pac_770.wav';
import VozE793 from './v_e_pac_793.wav';
import VozE0813 from './v_e_pac_0813.wav';
import VozE895 from './v_e_pac_895.wav';
import VozE900 from './v_e_pac_900.wav';
import VozE906 from './v_e_pac_906.wav';
import VozE909 from './v_e_pac_909.wav';
import VozE974 from './v_e_pac_974.wav';
import VozE1006 from './v_e_pac_1006.wav';
import VozE1027 from './v_e_pac_1027.wav';
import VozE1094 from './v_e_pac_1094.wav';
import VozE1126 from './v_e_pac_1126.wav';

import AudioCalibracao from './AUDIO_CALIBRACAO.mp3';
import VozCalibracao from './VOZ_CALIBRACAO.wav';

export const audios = {
  calibracao: {
    voz: VozCalibracao,
    audio: AudioCalibracao,
  },
  voz_rugosa: [VozE477, VozE768, VozE317, VozE657],
  voz_soprosa: [VozE757, VozE1065, VozE1139, VozE1154],
  voz_tensa: [VozE757, VozE1065, VozE1139, VozE1154],
  voz_normal: [VozE488, VozE565, VozE634, VozE670],
  voz_alterada: [VozE477, VozE757, VozE768, VozE1065],
  som_e: {
    VozE1157,
    VozE477,
    VozE757,
    VozE768,
    VozE1065,
    VozE1139,
    VozE1154,
    VozE1246,
    VozE317,
    VozE488,
    VozE565,
    VozE624,
    VozE634,
    VozE657,
    VozE670,
    VozE677,
    VozE689,
    VozE737,
    VozE746,
    VozE758,
    VozE770,
    VozE793,
    VozE0813,
    VozE895,
    VozE900,
    VozE906,
    VozE909,
    VozE974,
    VozE1006,
    VozE1027,
    VozE1094,
    VozE1126,
  },
};
