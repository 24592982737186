import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Button, Fade } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

import history from '~/services/history';

const useStyles = makeStyles((theme) => ({
  paperLabel: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
  paperText: {
    padding: theme.spacing(1),
    minHeight: '150px',
    overflow: 'auto',
  },
  paragraph: {
    lineHeight: '25px',
  },
}));

export default function Guide() {
  const classes = useStyles();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded]);

  return (
    <Fade in={loaded} timeout={1000}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Paper className={classes.paperLabel}>Funcionamento</Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paperText}>
            <p className={classes.paragraph}>
              O Ouvindo Vozes é um simulador de treinamento perceptivo-auditivo
              baseado na literatura e na experiência de fonoaudiólogos
              especialista na área.
            </p>
            <p className={classes.paragraph}>
              O treinamento é dividido em 4 etapas com nível de dificuldade
              crescente.
            </p>
            <p className={classes.paragraph}>
              Na primeira etapa de treinamento, o usuário treina a classificação
              da presença ou ausência de desvio vocal.
            </p>
            Na segunda etapa, o usuário treina a classificação do predomínio da
            qualidade vocal.
            <p className={classes.paragraph}>
              Na terceira etapa, o usuário é treinado para classificar os graus
              de alteração vocal de forma isolada.
            </p>
            <p className={classes.paragraph}>
              Na última etapa, o usuário treina, de forma simultânea, a
              classificação dos graus de alteração.
            </p>
            <p className={classes.paragraph}>
              Seja bem-vindo! Bom treinamento!
            </p>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paperLabel}>Objetivos</Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paperText}>
            O Ouvindo Vozes tem como objetivo treinar alunos e profissionais da
            Fonoaudiologia para que desenvolvam habilidades de percepção
            necessárias para realizar a julgamento perceptivo-auditiva da voz de
            forma confiável e consistente.
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Button
            startIcon={<ArrowBack fontSize="14" />}
            variant="contained"
            color="secondary"
            onClick={() => history.push('/')}
            size="small"
          >
            Voltar
          </Button>
        </Grid>
      </Grid>
    </Fade>
  );
}
