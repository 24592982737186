import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Fade,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Typography,
} from '@material-ui/core';

import Player from '~/components/Player';

import { useStyles } from './styles';
import { useDatabase } from '~/hooks/database';

import { audios } from '~/assets/audios';

export default function NumberScale() {
  const { id } = useParams();
  const classes = useStyles();
  const { select } = useDatabase();
  const [loaded, setLoaded] = useState(false);
  const [voz] = useState(audios.som_e[Math.floor(Math.random() * 22)]);
  const [data] = useState(select('fases', id));

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded]);

  return (
    <>
      <Fade in={loaded} timeout={1000}>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              Escala Numérica - {data.conceitos.escala_titulo}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Grau</TableCell>
                  <TableCell>Definição</TableCell>
                  <TableCell>Exemplo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.conceitos.escala.map((value, key) => (
                  <TableRow key={value}>
                    <TableCell>{key}</TableCell>
                    <TableCell>{value}</TableCell>
                    <TableCell>
                      <Player url={voz} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Fade>
    </>
  );
}
