import React, { useState, createContext, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

import api from '~/services/api';

const ClassificationContext = createContext({});

export const ClassificationProvider = ({ children }) => {
  const [data, setData] = useState({
    id: null,
    classificacaoInicial: null,
    resultadoInicial: null,
    classificacaoFinal: null,
    resultadoFinal: null,
    audios: [],
    audiosTreinamento: [],
    audiosRespondidos: [],
    resultadoId: null,
    aprovado: null,
  });

  const startPhase = useCallback(
    async (fase) => {
      try {
        const response = await api.get(`/audios/${fase}/?gabarito=true`);
        setData({
          classificacaoInicial: null,
          resultadoInicial: null,
          classificacaoFinal: null,
          resultadoFinal: null,
          audiosRespondidos: [],
          audiosTreinamento: [],
          resultadoId: null,
          aprovado: null,
          id: fase,
          audios: response.data,
        });
      } catch (error) {
        console.log(error);
      }
    },
    [data] // eslint-disable-line
  );

  const saveInitialResult = useCallback(
    (results) => {
      setData({ ...data, resultadoInicial: results });
    },
    [data]
  );

  const saveFinalResult = useCallback(
    (results) => {
      setData({ ...data, resultadoFinal: results });
    },
    [data]
  );
  const getAudios = useCallback(
    async (fase) => {
      try {
        const response = await api.get(`/audios/${fase}?gabarito=true`);
        setData({
          ...data,
          audios: response.data,
          audiosTreinamento: response.data,
        });
      } catch (error) {
        console.log(error);
      }
    },
    [data]
  );
  const addAudioAnswer = useCallback(
    async (audio) => {
      setData({
        ...data,
        audiosRespondidos: [...data.audiosRespondidos, audio],
      });
    },
    [data]
  );

  const approved = (numbers, faseId, nivel) => {
    if ([1, 2].includes(Number(faseId))) {
      return (
        ((numbers.acuracia || 0 / 1) * 100).toFixed(0) > nivel.acuracia &&
        ((numbers.especificidade / 1) * 100).toFixed(0) >
          nivel.especificidade &&
        ((numbers.sensibilidade / 1) * 100).toFixed(0) > nivel.sensibilidade &&
        numbers.concordancia > nivel.concordancia
      );
    }

    if ([3, 4].includes(Number(faseId))) {
      return (
        ((numbers.acuracia || 0 / 1) * 100).toFixed(0) > nivel.acuracia &&
        numbers.concordancia > nivel.concordancia
      );
    }

    let available = true;
    // eslint-disable-next-line
    Object.keys(numbers).map((propriedade) => {
      if (
        ['grau_geral', 'rugosidade', 'soprosidade', 'tensao'].includes(
          propriedade
        )
      ) {
        const acuracia = ((numbers[propriedade].acuracia / 1) * 100).toFixed(0);
        const concordancia = (
          (numbers[propriedade].concordancia / 1) *
          100
        ).toFixed(0);
        if (
          !available ||
          acuracia < nivel.acuracia ||
          concordancia < nivel.concordancia
        ) {
          available = false;
        }
      }
    });

    return available;
  };

  const processResult = useCallback(
    async (faseId, nivel) => {
      try {
        if (!data.resultadoInicial) {
          // console.log('fase inicial', data.audiosRespondidos.length);
          const response = await api.post(
            `/process-result/${faseId}`,
            data.audiosRespondidos
          );
          setData({
            ...data,
            resultadoInicial: response.data,
            audiosRespondidos: [],
            classificacaoInicial: data.audiosRespondidos,
          });
        } else {
          // console.log('fase final', data.audiosRespondidos.length);
          const response = await api.post(
            `/process-result/${faseId}`,
            data.audiosRespondidos
          );

          const aprovado = approved(response.data, faseId, nivel);

          await api.post('/result', {
            fase: faseId,
            resultados: data.resultadoInicial,
            initial: true,
            audios: data.classificacaoInicial,
          });

          const responseResult = await api.post('/result', {
            fase: faseId,
            resultados: response.data,
            initial: false,
            audios: data.audiosRespondidos,
            aprovado,
          });

          setData({
            ...data,
            resultadoFinal: responseResult.data,
            aprovado,
            audiosRespondidos: [],
            classificacaoFinal: data.audiosRespondidos,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [data] // eslint-disable-line
  );

  return (
    <ClassificationContext.Provider
      value={{
        fase: data,
        startPhase,
        saveInitialResult,
        saveFinalResult,
        getAudios,
        addAudioAnswer,
        processResult,
      }}
    >
      {children}
    </ClassificationContext.Provider>
  );
};

ClassificationProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export function useClassification() {
  const context = useContext(ClassificationContext);

  return context;
}
