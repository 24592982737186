import React, { useRef, useCallback, useState, useEffect } from 'react';
import {
  Button,
  Link,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  Fade,
  Paper,
} from '@material-ui/core';
import { Visibility, VisibilityOff, AccountBox } from '@material-ui/icons';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import TextField from '~/components/Utils/TextField';
import Select from '~/components/Utils/Select';
import Switch from '~/components/Utils/Switch';

import getValidationErrors from '~/utils/getValidationErrors';
import getGroups from '~/utils/getGroups';
import getExperienceLevels from '~/utils/getExperienceLevels';

import history from '~/services/history';
import { useAuth } from '~/hooks/auth';
import { useLoading } from '~/hooks/loading';

import { useStyles } from './styles';

export default function SignUp() {
  const classes = useStyles();
  const formRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const { signUp } = useAuth();
  const { loadingShowed, showLoading, hideLoading } = useLoading();

  const [showPassword, setShowPassword] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isSinger, setIsSinger] = useState(false);
  const [isGraduateStudent, setIsGraduateStudent] = useState(false);

  const GRADUATE_STUDENT = 2;

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Precisamos de um email válido.')
            .required('Precisamos que informe o seu e-mail.'),
          nome: Yup.string().required('Precisamos que informe o seu nome.'),
          idade: Yup.number().required('Precisamos que informe a sua idade.'),
          grupo: Yup.number().required('Precisamos que selecione um grupo.'),
          experiencia: Yup.number().required(
            'Precisamos que selecione um nível de experiência.'
          ),
          periodo: Yup.string().when('grupo', {
            is: (grupo) => grupo === GRADUATE_STUDENT,
            then: Yup.string().required(
              'Precisamos que informe o período que você está cursando.'
            ),
            otherwise: Yup.string().nullable(),
          }),
          horas_treinamento: Yup.string().nullable(),
          cantor: Yup.bool(),
          tempo_cantor: Yup.string().when('cantor', {
            is: true,
            then: Yup.string().required(
              'Precisamos que informe a quanto tempo você é cantor.'
            ),
            otherwise: Yup.string().nullable(),
          }),
          graduado_musica: Yup.bool(),
          voz_normal: Yup.bool(),
          queixa_auditiva: Yup.bool(),
          tempo_disponivel: Yup.bool(),
          senha: Yup.string()
            .min(4, 'Mínimo de 4 dígitos.')
            .required('A senha é obrigatória.'),
          confirmar_senha: Yup.string()
            .required('As senhas não conferem.')
            .oneOf([Yup.ref('senha'), null], 'As senhas não conferem.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        showLoading();
        await signUp({ ...data, fase_atual: 21 });
        hideLoading();
        enqueueSnackbar('Cadastro efetuado com sucesso', {
          variant: 'success',
        });
        history.push('/signin');
      } catch (err) {
        hideLoading();
        if (err.inner) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);

          return;
        }

        if (err.message === 'Network Error') {
          enqueueSnackbar(
            'Serviço temporareamente indisponível, tente novamente mais tarde.',
            { variant: 'info' }
          );

          return;
        }

        enqueueSnackbar(
          'Ocorreu um erro ao tentar realizar está ação, entre em contato com o suporte.',
          { variant: 'error' }
        );
      }
    },
    [enqueueSnackbar, signUp, showLoading, hideLoading]
  );

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Fade in={loaded} timeout={1000}>
      <Paper className={classes.paper}>
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          alignItems="center"
        >
          <Grid item xs={8}>
            <Typography component="h1" variant="h4" className={classes.title}>
              Cadastrar
            </Typography>
            <Typography
              component="p"
              variant="body2"
              className={classes.subtitle}
            >
              Cadastre-se para acessar a plataforma
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.logo}>
            <AccountBox color="primary" fontSize="large" />
          </Grid>
        </Grid>
        <Form className={classes.form} ref={formRef} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            fullWidth
            label="Nome"
            name="nome"
            autoComplete="name"
            color="primary"
            margin="normal"
            id="name"
            autoFocus
            disabled={loadingShowed}
            size="small"
          />
          <TextField
            variant="outlined"
            fullWidth
            label="Idade"
            name="idade"
            autoComplete="age"
            color="primary"
            margin="normal"
            autoFocus
            disabled={loadingShowed}
            size="small"
            type="number"
          />
          <TextField
            variant="outlined"
            fullWidth
            label="E-mail"
            name="email"
            autoComplete="email"
            color="primary"
            margin="normal"
            autoFocus
            disabled={loadingShowed}
            size="small"
            id="email"
          />
          <Select
            native
            name="grupo"
            onChange={(event) => {
              setIsGraduateStudent(
                GRADUATE_STUDENT === Number(event.target.value)
              );
            }}
            label="Grupo"
            formControlProps={{
              variant: 'outlined',
              margin: 'normal',
              fullWidth: true,
              size: 'small',
              notched: true,
              disabled: loadingShowed,
            }}
            inputLabelProps={{
              shrink: true,
            }}
          >
            <option value={0} selected disabled>
              Selecione
            </option>
            {getGroups().map((group) => (
              <option value={group.id} key={group.id}>
                {group.description}
              </option>
            ))}
          </Select>
          {isGraduateStudent && (
            <TextField
              variant="outlined"
              fullWidth
              label="Se aluno de graduação, em que período está?"
              name="periodo"
              autoComplete="period"
              color="primary"
              margin="normal"
              autoFocus
              disabled={loadingShowed}
              size="small"
              id="period"
              type="number"
            />
          )}
          <Select
            native
            name="experiencia"
            label="Nível de experiência"
            formControlProps={{
              variant: 'outlined',
              margin: 'normal',
              fullWidth: true,
              size: 'small',
              notched: true,
              disabled: loadingShowed,
            }}
            inputLabelProps={{
              shrink: true,
            }}
          >
            <option value={0} selected disabled>
              Selecione
            </option>
            {getExperienceLevels().map((level) => (
              <option value={level.id} key={level.id}>
                {level.description}
              </option>
            ))}
          </Select>
          <TextField
            variant="outlined"
            fullWidth
            label="Quantas horas de treinamento em avaliação perceptivo-auditiva você possui?"
            name="horas_treinamento"
            autoComplete="trainning_time"
            color="primary"
            margin="normal"
            autoFocus
            disabled={loadingShowed}
            size="small"
            id="trainning_time"
            type="number"
          />
          <Typography
            component="p"
            variant="body2"
            className={classes.marginNormal}
          >
            Você é cantor amador ou profissional?
          </Typography>
          <div>
            <Typography component="span" variant="caption">
              Não
            </Typography>
            <Switch
              onChange={() => setIsSinger(!isSinger)}
              name="cantor"
              color="primary"
              defaultChecked={isSinger}
            />
            <Typography component="span" variant="caption">
              Sim
            </Typography>
          </div>
          {isSinger && (
            <TextField
              variant="outlined"
              fullWidth
              label="A quanto tempo você é cantor?"
              name="tempo_cantor"
              autoComplete="time_singer"
              color="primary"
              margin="normal"
              autoFocus
              disabled={loadingShowed}
              size="small"
              id="time_singer"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          <Typography
            component="p"
            variant="body2"
            className={classes.marginNormal}
          >
            Possui alguma formação em música?
          </Typography>
          <div>
            <Typography component="span" variant="caption">
              Não
            </Typography>
            <Switch
              name="graduado_musica"
              color="primary"
              defaultChecked={false}
            />
            <Typography component="span" variant="caption">
              Sim
            </Typography>
          </div>
          <Typography
            component="p"
            variant="body2"
            className={classes.marginNormal}
          >
            Você considera sua voz normal?
          </Typography>
          <div>
            <Typography component="span" variant="caption">
              Não
            </Typography>
            <Switch name="voz_normal" color="primary" defaultChecked={false} />
            <Typography component="span" variant="caption">
              Sim
            </Typography>
          </div>
          <Typography
            component="p"
            variant="body2"
            className={classes.marginNormal}
          >
            Possui deficiência ou queixa auditiva?
          </Typography>
          <div>
            <Typography component="span" variant="caption">
              Não
            </Typography>
            <Switch
              name="queixa_auditiva"
              color="primary"
              defaultChecked={false}
            />
            <Typography component="span" variant="caption">
              Sim
            </Typography>
          </div>
          <Typography
            component="p"
            variant="body2"
            className={classes.marginNormal}
          >
            Tem disponibilidade de tempo para se dedicar ao curso de treinamento
            auditivo?
          </Typography>
          <div>
            <Typography component="span" variant="caption">
              Não
            </Typography>
            <Switch
              name="tempo_disponivel"
              color="primary"
              defaultChecked={false}
            />
            <Typography component="span" variant="caption">
              Sim
            </Typography>
          </div>
          <TextField
            variant="outlined"
            fullWidth
            name="senha"
            label="Senha"
            type={showPassword ? 'text' : 'password'}
            color="primary"
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            autoFocus
            disabled={loadingShowed}
            size="small"
          />
          <TextField
            variant="outlined"
            fullWidth
            name="confirmar_senha"
            label="Confirmar senha"
            type={showPassword ? 'text' : 'password'}
            color="primary"
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            autoFocus
            disabled={loadingShowed}
            size="small"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loadingShowed}
            size="small"
          >
            Enviar
          </Button>
        </Form>
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            history.push('/signin');
          }}
          color="inherit"
        >
          Entrar na plataforma
        </Link>
      </Paper>
    </Fade>
  );
}
