import React, { createContext, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

import database from '../data/database';

const DatabaseContext = createContext({});

export const DatabaseProvider = ({ children }) => {
  const select = useCallback((table, id) => {
    const data = database[table].filter((row) => row.id === Number(id));
    return data[0];
  }, []);

  return (
    <DatabaseContext.Provider
      value={{
        select,
      }}
    >
      {children}
    </DatabaseContext.Provider>
  );
};

DatabaseProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export function useDatabase() {
  const context = useContext(DatabaseContext);

  return context;
}
