import React from 'react';
import { Paper, Typography, Grid, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import Player from '~/components/Player';

import { audios } from '~/assets/audios';

import { useStyles } from './styles';

import history from '~/services/history';

import { useAuth } from '~/hooks/auth';

export default function Adjustment() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const incorrectChoice = () => {
    enqueueSnackbar(
      'Ajuste o áudio até que você consiga ouvir o som ao fundo.',
      { variant: 'info' }
    );
  };

  const correctChoice = () => {
    enqueueSnackbar('Opção correta, vamos para a próxima etapa.', {
      variant: 'success',
    });

    history.push(`/presentation/${user.fase_atual}`);
  };

  return (
    <Paper>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h4">Ajuste de áudio</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            Ajuste o volume do seu dispositivo de modo que consiga perceber e
            reconhecer o som que aparece ao fundo da voz. Não reajuste o volume
            após essa etapa.
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.player}>
          <Player url={audios.calibracao.audio} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            Qual som você escutou ao fundo?
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.buttons}>
          <Button variant="contained" color="primary" onClick={incorrectChoice}>
            APITO
          </Button>
          <Button variant="contained" color="primary" onClick={correctChoice}>
            SINO
          </Button>
          <Button variant="contained" color="primary" onClick={incorrectChoice}>
            FLAUTA
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
