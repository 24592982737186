import React, { createContext, useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import jwt from 'jsonwebtoken';

import api from '~/services/api';
import history from '~/services/history';
import authConfig from '~/config/AuthConfig';

// import { useUtils } from '~/hooks/utils';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  // const { updateDarkMode } = useUtils();

  const tokenStorage = localStorage.getItem('@ouvindo-vozes:token');

  const [data, setData] = useState();

  const tokenIsValid = (token) => {
    if (token) {
      try {
        jwt.verify(token, authConfig.public);
        return true;
      } catch (error) {
        return false;
      }
    }

    return false;
  };

  const startup = async () => {
    if (tokenStorage && (!data || !data.profile)) {
      if (tokenIsValid(tokenStorage)) {
        history.push('/adjustment');
      } else {
        localStorage.removeItem('@ouvindo-vozes:token');
      }
    }
  };

  startup();

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('/session', {
      email,
      password,
    });

    const { token } = response.data;

    localStorage.setItem('@ouvindo-vozes:token', token);

    api.defaults.headers.authorization = `Bearer ${token}`;

    const { id, nome, fase_atual, is_admin } = jwt.decode(token);
    setData({ id, nome, email, fase_atual, is_admin });

    history.push('/adjustment');
  }, []); // eslint-disable-line

  const recoverPassword = useCallback(async ({ email }) => {
    await api.post('recover-password', { email });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@ouvindo-vozes:token');

    setData(null);
  }, []);

  const signUp = useCallback(async (user) => {
    await api.post('/user', user);
  }, []);

  const updateUser = useCallback(
    async (payload) => {
      const response = await api.put(`user/${data.id}`, payload);
      setData(response.data);
    },
    [data]
  );

  const checkToken = useCallback(async ({ token }) => {
    await api.post('check-token', { token });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        unitsRoles: (data && data.unitsRoles) || null,
        activeUnit: (data && data.activeUnit) || null,
        // user: (data && data.profile) || null,
        user: data || null,
        token: (data && data.token) || null,
        signIn,
        updateUser,
        signOut,
        signUp,
        checkToken,
        recoverPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(
      'useAuth deve ser utilizando dentro do escopo do AuthProvider'
    );
  }

  return context;
}
