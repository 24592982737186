import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Typography,
  Grid,
  Button,
  Fade,
  Paper,
  MobileStepper,
} from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';

import Player from '~/components/Player';
import ClassificationComponent from '~/components/Classification';
import Help from '~/components/Help';

import { audios } from '~/assets/audios';

import history from '~/services/history';
import api from '~/services/api';

import { useStyles } from './styles';
import { useDatabase } from '~/hooks/database';
import { useClassification } from '~/hooks/classification';

function Classification() {
  const classes = useStyles();

  const { fase, addAudioAnswer, processResult } = useClassification();
  const { select } = useDatabase();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const { id } = useParams();
  const { pathname } = useLocation();

  const [data] = useState(select('fases', id));
  const [nivel] = useState(select('niveis', data.nivel_id));
  const [loaded, setLoaded] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [answerAvailable, setAnswerAvailable] = useState(false);
  const [selecteds, setSelecteds] = useState([]);
  const [categorias] = useState(data.classificacao_inicial.categorias);
  const [graus] = useState(data.classificacao_inicial.graus);
  const [predominios] = useState(data.classificacao_inicial.predominios);
  const [isTraining] = useState(pathname.includes('training'));
  const [confirmed, setConfirmed] = useState(false);
  const [nextAnswerAvailable, setNextAnswerAvailable] = useState(false);
  const [audioAnswer, setAudioAnswer] = useState(null);
  const [erros, setErros] = useState([]);
  const [finishClassification, setFinishClassification] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded]);

  const processClassification = async () => {
    if (isTraining) {
      history.push(`${data.treino.proximo}/${id}`);
    } else if (fase.classificacaoInicial) {
      await processResult(fase.id, nivel);
      history.push(`${data.classificacao_final.proximo}/${fase.id}`);
    } else {
      await processResult(fase.id, nivel);
      history.push(`${data.classificacao_inicial.proximo}/${fase.id}`);
    }
  };

  useEffect(() => {
    if (finishClassification) {
      processClassification();
    }
  }, [finishClassification]); // eslint-disable-line

  useEffect(() => {
    if (erros.length) {
      confirm({
        title: 'Você pode melhorar',
        description: 'Ouça a voz novamente, e então, continue.',
        allowClose: true,
        confirmationText: 'Ok, continuar',
        cancellationButtonProps: {
          className: classes.hide,
        },
      });

      setErros([]);
    }
  }, [erros, id]); // eslint-disable-line

  const canConfirm = () =>
    (id >= 21 && selecteds.length === 5) || (id < 21 && selecteds.length > 0);

  const handlePlay = () => {
    if (!answerAvailable) {
      setAnswerAvailable(true);
    }

    if (isTraining && canConfirm() && !nextAnswerAvailable) {
      setNextAnswerAvailable(true);
    }
  };

  const addSelection = (categoriaSelected, grauSelected) => {
    if (!categoriaSelected) {
      setSelecteds([grauSelected]);
    } else if (!grauSelected) {
      setSelecteds([categoriaSelected]);
    } else {
      let newSelecteds = [...selecteds];
      if (
        selecteds.some(
          (selected) => selected.categoriaSelected === categoriaSelected
        )
      ) {
        newSelecteds = newSelecteds.filter(
          (selected) => selected.categoriaSelected !== categoriaSelected
        );
      }

      newSelecteds.push({ categoriaSelected, grauSelected });
      setSelecteds(newSelecteds);
    }
  };

  const canNext = () =>
    (!isTraining || (isTraining && confirmed && nextAnswerAvailable)) &&
    ((!categorias && selecteds.length > 0) ||
      (!graus && selecteds.length > 0) ||
      (categorias &&
        categorias.length + 1 === selecteds.length &&
        Number(id) >= 21) ||
      (Number(id) < 21 && selecteds.length > 0));

  const handleConfirm = async () => {
    setConfirmed(true);

    const newStateErros = [];

    const answer = await api.get(`audio/${fase.audios[activeStep].id}`);
    setAudioAnswer(answer.data);

    // eslint-disable-next-line
    selecteds.map((selected) => {
      if ([1, 2, 3, 4].includes(Number(id))) {
        if ([1, 2].includes(Number(id)) && !answer.data[selected]) {
          newStateErros.push(selected);
        } else if (
          answer.data.predominio &&
          answer.data.predominio.toUpperCase() !== selected.toUpperCase()
        ) {
          newStateErros.push(selected);
        }
      } else if (
        answer.data[selected.categoriaSelected].toUpperCase() !==
        selected.grauSelected.toUpperCase()
      ) {
        newStateErros.push(selected.categoriaSelected);
      }
    });

    if (!newStateErros.length) {
      enqueueSnackbar('Opção correta, próximo áudio disponível.', {
        variant: 'success',
        autoHideDuration: 1500,
      });
      setNextAnswerAvailable(true);
    }

    setErros(newStateErros);
  };

  const handleNext = async () => {
    await addAudioAnswer({ ...fase.audios[activeStep], answers: selecteds });
    setSelecteds([]);
    setAnswerAvailable(false);

    if (isTraining) {
      setConfirmed(false);
      setNextAnswerAvailable(false);
      setAudioAnswer(null);
    }

    if (fase.audios[activeStep + 1]) {
      setActiveStep(activeStep + 1);
    } else {
      setFinishClassification(true);
    }
  };

  return (
    <Fade in={loaded} timeout={1000}>
      <Paper>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="h5">
              ETAPA {data.etapa_id} - FASE {data.id}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              {(isTraining && 'Treinamento') ||
                (fase.classificacaoInicial && 'Avaliação Final') ||
                'Avaliação inicial'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <MobileStepper
              variant="dots"
              steps={fase.audios.length}
              activeStep={activeStep}
              position="static"
              className={classes.progress}
            />
          </Grid>
          <Grid item xs={12}>
            {data.classificacao_inicial.descricoes.map((descricao) => (
              <Typography variant="h6" align="center" key={Math.random()}>
                {descricao}
              </Typography>
            ))}
          </Grid>
          {fase.classificacaoInicial && !isTraining && <Grid item xs={4} />}
          <Grid
            item
            xs={(fase.classificacaoInicial && !isTraining && 4) || 12}
            className={classes.player}
          >
            {fase.audios.length && (
              <Player
                url={audios.som_e[fase.audios[activeStep].path]}
                onClickCapture={handlePlay}
              />
            )}
          </Grid>
          {fase.classificacaoInicial && !isTraining && (
            <Grid item xs={4} className={classes.help}>
              <Help categorias={categorias} graus={graus} />
            </Grid>
          )}

          <ClassificationComponent
            predominios={predominios}
            categorias={categorias}
            graus={graus}
            addSelecao={addSelection}
            selecionados={selecteds}
            respostasDisponiveis={answerAvailable}
            escolhasConfirmadas={confirmed}
            audio={fase.audios[activeStep]}
            treino={isTraining}
            audioAnswer={audioAnswer}
          />

          {fase.audios &&
            fase.audios[activeStep] &&
            (fase.audios[activeStep].normal ||
              fase.audios[activeStep].alterada) && (
              <pre>
                Gabarito: {JSON.stringify(fase.audios[activeStep], null, 2)}
              </pre>
            )}

          <Grid item xs={12} className={classes.next}>
            {(((isTraining && confirmed) || !isTraining) && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={!canNext()}
              >
                {fase.audiosRespondidos.length === fase.audios.length - 1
                  ? 'AVANÇAR'
                  : 'PRÓXIMO'}
              </Button>
            )) || (
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfirm}
                disabled={!canConfirm()}
              >
                CONFIRMAR
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Fade>
  );
}

export default Classification;
