import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Fade } from '@material-ui/core';

import Player from '~/components/Player';
import NumberScale from '~/components/NumberScale';

import { useStyles } from './styles';

import { audios } from '~/assets/audios';

export default function Concept({ concepts }) {
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded]);

  const getConceptVoice = (type) => {
    switch (type) {
      case 'voz_alterada':
        return audios.voz_alterada[Math.floor(Math.random() * 3)];
      case 'voz_rugosa':
        return audios.voz_rugosa[Math.floor(Math.random() * 3)];
      case 'voz_soprosa':
        return audios.voz_soprosa[Math.floor(Math.random() * 3)];
      case 'voz_tensa':
        return audios.voz_tensa[Math.floor(Math.random() * 3)];
      default:
        return audios.voz_normal[Math.floor(Math.random() * 3)];
    }
  };

  return (
    <Fade in={loaded} timeout={1000}>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            Aprendizagem de Conceitos
          </Typography>
        </Grid>
        {concepts.corpo.map((concept) => {
          if (concept.includes('{voz')) {
            const audio = getConceptVoice(
              concept.replace(/\{/gi, '').replace(/\}/gi, '')
            );
            return (
              <Grid item xs={12} className={classes.player} key={concept}>
                <Player url={audio} />
              </Grid>
            );
          }

          if (concept === '{escala_numerica}') {
            return (
              <Grid item xs={12} className={classes.numberScale}>
                <NumberScale />
              </Grid>
            );
          }

          return (
            <Grid item xs={12} className={classes.concept}>
              <Typography variant="h6" align="center">
                {concept}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Fade>
  );
}

Concept.propTypes = {
  concepts: PropTypes.shape().isRequired,
};
