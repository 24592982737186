export const fases = [
  {
    id: 1,
    nivel_id: 1,
    avaliar_nivel: false,
    ultima_fase: false,
    etapa_id: 1,
    apresentacao: {
      titulo: 'ETAPA 1 - Presença de desvio vocal',
      descricoes: [
        'Nesta etapa você será treinado para classificar uma voz em normal/sem desvio vocal ou em alterada/com desvio vocal',
        'Esta etapa possui 2 FASES de treinamento. Você precisará ser aprovado nestas fases para poder avançar para a próxima etapa.',
      ],
      proximo: '/concepts',
    },
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Considera-se uma voz como normal ou sem desvio vocal quando ela apresenta qualidade do com agradável, com frequência e intensidade adequadas para a idade e sexo do falando, além disso sua emissão é natural, sem esforço e sem a presença marcante de ruído',
            'Ouça alguns exemplos de vozes consideradas normais/sem desvio vocal',
            '{voz_normal}',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Considera-se uma voz como alterada ou com desvio vocal quando ela apresenta qualidade do som desagradável, com frequência e intensidade inadequadas para a idade e sexo do falante, além disso sua emissão é feita com esforço e com a presença marcante de ruído',
            'Ouça alguns exemplos de vozes consideradas alteradas/com desvio vocal',
            '{voz_alterada}',
          ],
        },
      ],
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      titulo: 'Avaliação Inicial',
      graus: ['normal', 'alterada'],
      proximo: '/training',
      descricoes: ['Classifique as vozes a seguir'],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      graus: ['normal', 'alterada'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      graus: ['normal', 'alterada'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/presentation',
    },
  },
  {
    id: 2,
    nivel_id: 1,
    avaliar_nivel: false,
    ultima_fase: false,
    etapa_id: 1,
    apresentacao: {
      titulo: 'ETAPA 1 - Presença de desvio vocal',
      descricoes: [
        'Nesta etapa você será treinado para classificar uma voz em normal/sem desvio vocal ou em alterada/com desvio vocal',
        'Esta etapa possui 2 FASES de treinamento. Você precisará ser aprovado nestas fases para poder avançar para a próxima etapa.',
      ],
      proximo: '/concepts',
    },
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Considera-se uma voz como normal ou sem desvio vocal quando ela apresenta qualidade do com agradável, com frequência e intensidade adequadas para a idade e sexo do falando, além disso sua emissão é natural, sem esforço e sem a presença marcante de ruído',
            'Ouça alguns exemplos de vozes consideradas normais/sem desvio vocal',
            '{voz_normal}',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Considera-se uma voz como alterada ou com desvio vocal quando ela apresenta qualidade do som desagradável, com frequência e intensidade inadequadas para a idade e sexo do falante, além disso sua emissão é feita com esforço e com a presença marcante de ruído',
            'Ouça alguns exemplos de vozes consideradas alteradas/com desvio vocal',
            '{voz_alterada}',
          ],
        },
      ],
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      titulo: 'Avaliação Inicial',
      graus: ['normal', 'alterada'],
      proximo: '/training',
      descricoes: ['Classifique as vozes a seguir'],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      graus: ['normal', 'alterada'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      graus: ['normal', 'alterada'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/presentation',
    },
  },
  {
    id: 3,
    nivel_id: 1,
    avaliar_nivel: false,
    ultima_fase: false,
    etapa_id: 2,
    apresentacao: {
      titulo: 'ETAPA 2 - Predomínio de desvio vocal',
      descricoes: [
        'Nesta etapa você será treinado para classificar o tipo de desvio vocal que é predominante na voz',
        'Esta etapa possui 2 FASES de treinamento. Você precisará ser aprovado nestas fases para poder avançar para a próxima etapa.',
      ],
      proximo: '/concepts',
    },
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Este momento é destinado a aprendizagem dos conceitos que serão trabalhados neste nível. Leia atentamente as definições e ouça os exemplos quantas vezes achar necessário.',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Rugosidade',
            'Considera-se que uma voz como rugosa quando características auditivas de quebra de sonoridade estão presentes durante a emissão...',
            'Ouça um exemplo de voz considerada Rugosa',
            '{voz_rugosa}',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Soprosidade',
            'Considera-se uma voz como soprosa quando características auditivas de ruído de ar presentes durante a emissão...',
            'Ouça um exemplo de voz considerada Soprosa',
            '{voz_soprosa}',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Tensão',
            'Considera-se uma voz como tensa quando características auditivas de esforço estão presentes durante a emissão...',
            'Ouça um exemplo de voz considerada Tensa',
            '{voz_tensa}',
          ],
        },
      ],
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      titulo: 'Avaliação Inicial',
      categorias: ['normal', 'rugosa', 'soprosa', 'tensa'],
      proximo: '/training',
      descricoes: ['Classifique as vozes a seguir'],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      categorias: ['normal', 'rugosa', 'soprosa', 'tensa'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      categorias: ['normal', 'rugosa', 'soprosa', 'tensa'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/presentation',
    },
  },
  {
    id: 4,
    nivel_id: 1,
    avaliar_nivel: true,
    ultima_fase: false,
    etapa_id: 2,
    apresentacao: {
      titulo: 'ETAPA 2 - Predomínio de desvio vocal',
      descricoes: [
        'Nesta etapa você será treinado para classificar o tipo de desvio vocal que é predominante na voz',
        'Esta etapa possui 2 FASES de treinamento. Você precisará ser aprovado nestas fases para poder avançar para a próxima etapa.',
      ],
      proximo: '/concepts',
    },
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Este momento é destinado a aprendizagem dos conceitos que serão trabalhados neste nível. Leia atentamente as definições e ouça os exemplos quantas vezes achar necessário.',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Rugosidade',
            'Considera-se que uma voz como rugosa quando características auditivas de quebra de sonoridade estão presentes durante a emissão...',
            'Ouça um exemplo de voz considerada Rugosa',
            '{voz_rugosa}',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Soprosidade',
            'Considera-se uma voz como soprosa quando características auditivas de ruído de ar presentes durante a emissão...',
            'Ouça um exemplo de voz considerada Soprosa',
            '{voz_soprosa}',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Tensão',
            'Considera-se uma voz como tensa quando características auditivas de esforço estão presentes durante a emissão...',
            'Ouça um exemplo de voz considerada Tensa',
            '{voz_tensa}',
          ],
        },
      ],
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      titulo: 'Avaliação Inicial',
      categorias: ['normal', 'rugosa', 'soprosa', 'tensa'],
      proximo: '/training',
      descricoes: ['Classifique as vozes a seguir'],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      categorias: ['normal', 'rugosa', 'soprosa', 'tensa'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      categorias: ['normal', 'rugosa', 'soprosa', 'tensa'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/presentation',
    },
  },
  {
    id: 5,
    nivel_id: 1,
    avaliar_nivel: false,
    ultima_fase: false,
    etapa_id: 3,
    apresentacao: {
      titulo: 'ETAPA 3 – Avaliação isolada dos parâmetros',
      descricoes: [
        'Nesta etapa você será treinado para classificar os graus de alterações do desvio vocal',
        'Esta etapa possui 16 FASES de treinamento. Você precisará ser aprovado nestas fases para poder avançar para a próxima etapa.',
      ],
      proximo: '/concepts',
    },
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Este momento é destinado a aprendizagem dos conceitos que serão trabalhados neste nível. Leia atentamente as definições e ouça os exemplos quantas vezes achar necessário',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Um dos parâmetros vocais avaliados na APA é o Grau Geral de alteração (G). Este parâmetro avalia o alteração vocal de forma global e pode ser classificado como normal, leve, moderado ou intenso.',
            'Para a classificação deste parâmetro utilizamos escalas da seguinte maneira:',
            '{escala_numerica}',
          ],
        },
      ],
      escala: [
        'Corresponde a ausência de desvio vocal (normalidade)',
        'Presença de Grau Geral leve',
        'Presença de Grau Geral moderado',
        'Presença de Grau Geral intenso',
      ],
      escala_titulo: 'Grau Geral',
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      titulo: 'Avaliação Inicial',
      categorias: [{ nome: 'grau_geral', descricao: 'Grau Geral' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/training',
      descricoes: ['Classifique o Grau Geral das vozes a seguir'],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      categorias: [{ nome: 'grau_geral', descricao: 'Grau Geral' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      categorias: [{ nome: 'grau_geral', descricao: 'Grau Geral' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/concepts',
    },
  },
  {
    id: 6,
    nivel_id: 1,
    avaliar_nivel: false,
    ultima_fase: false,
    etapa_id: 3,
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Este momento é destinado a aprendizagem dos conceitos que serão trabalhados neste nível. Leia atentamente as definições e ouça os exemplos quantas vezes achar necessário',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Um dos parâmetros vocais avaliados na APA é o Grau Geral de alteração (G). Este parâmetro avalia o alteração vocal de forma global e pode ser classificado como normal, leve, moderado ou intenso.',
            'Para a classificação deste parâmetro utilizamos escalas da seguinte maneira:',
            '{escala_numerica}',
          ],
        },
      ],
      escala: [
        'Corresponde a ausência de desvio vocal (normalidade)',
        'Presença de Grau Geral leve',
        'Presença de Grau Geral moderado',
        'Presença de Grau Geral intenso',
      ],
      escala_titulo: 'Grau Geral',
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      titulo: 'Avaliação Inicial',
      categorias: [{ nome: 'grau_geral', descricao: 'Grau Geral' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/training',
      descricoes: ['Classifique o Grau Geral das vozes a seguir'],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      categorias: [{ nome: 'grau_geral', descricao: 'Grau Geral' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      categorias: [{ nome: 'grau_geral', descricao: 'Grau Geral' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/concepts',
    },
  },
  {
    id: 7,
    nivel_id: 1,
    avaliar_nivel: false,
    ultima_fase: false,
    etapa_id: 3,
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Este momento é destinado a aprendizagem dos conceitos que serão trabalhados neste nível. Leia atentamente as definições e ouça os exemplos quantas vezes achar necessário',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Um dos parâmetros vocais avaliados na APA é o Grau Geral de alteração (G). Este parâmetro avalia o alteração vocal de forma global e pode ser classificado como normal, leve, moderado ou intenso.',
            'Para a classificação deste parâmetro utilizamos escalas da seguinte maneira:',
            '{escala_numerica}',
          ],
        },
      ],
      escala: [
        'Corresponde a ausência de desvio vocal (normalidade)',
        'Presença de Grau Geral leve',
        'Presença de Grau Geral moderado',
        'Presença de Grau Geral intenso',
      ],
      escala_titulo: 'Grau Geral',
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      titulo: 'Avaliação Inicial',
      categorias: [{ nome: 'grau_geral', descricao: 'Grau Geral' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/training',
      descricoes: ['Classifique o Grau Geral das vozes a seguir'],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      categorias: [{ nome: 'grau_geral', descricao: 'Grau Geral' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      categorias: [{ nome: 'grau_geral', descricao: 'Grau Geral' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/concepts',
    },
  },
  {
    id: 8,
    nivel_id: 1,
    avaliar_nivel: true,
    ultima_fase: false,
    etapa_id: 3,
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Este momento é destinado a aprendizagem dos conceitos que serão trabalhados neste nível. Leia atentamente as definições e ouça os exemplos quantas vezes achar necessário',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Um dos parâmetros vocais avaliados na APA é o Grau Geral de alteração (G). Este parâmetro avalia o alteração vocal de forma global e pode ser classificado como normal, leve, moderado ou intenso.',
            'Para a classificação deste parâmetro utilizamos escalas da seguinte maneira:',
            '{escala_numerica}',
          ],
        },
      ],
      escala: [
        'Corresponde a ausência de desvio vocal (normalidade)',
        'Presença de Grau Geral leve',
        'Presença de Grau Geral moderado',
        'Presença de Grau Geral intenso',
      ],
      escala_titulo: 'Grau Geral',
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      titulo: 'Avaliação Inicial',
      categorias: [{ nome: 'grau_geral', descricao: 'Grau Geral' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/training',
      descricoes: ['Classifique o Grau Geral das vozes a seguir'],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      categorias: [{ nome: 'grau_geral', descricao: 'Grau Geral' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      categorias: [{ nome: 'grau_geral', descricao: 'Grau Geral' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/concepts',
    },
  },
  {
    id: 9,
    nivel_id: 1,
    avaliar_nivel: false,
    ultima_fase: false,
    etapa_id: 3,
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Este momento é destinado a aprendizagem dos conceitos que serão trabalhados neste nível. Leia atentamente as definições e ouça os exemplos quantas vezes achar necessário',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Um dos parâmetros vocais avaliados na APA é o Grau Geral de alteração (G). Este parâmetro avalia o alteração vocal de forma global e pode ser classificado como normal, leve, moderado ou intenso.',
            'Para a classificação deste parâmetro utilizamos escalas da seguinte maneira:',
            '{escala_numerica}',
          ],
        },
      ],
      escala: [
        'Corresponde a ausência de Rugosidade (normalidade)',
        'Presença de Rugosidade leve',
        'Presença de Rugosidade moderado',
        'Presença de Rugosidade intenso',
      ],
      escala_titulo: 'Rugosidade',
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      titulo: 'Avaliação Inicial',
      categorias: [{ nome: 'rugosidade', descricao: 'Rugosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/training',
      descricoes: ['Classifique o Grau de Rugosidade das vozes a seguir'],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      categorias: [{ nome: 'rugosidade', descricao: 'Rugosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      categorias: [{ nome: 'rugosidade', descricao: 'Rugosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/concepts',
    },
  },
  {
    id: 10,
    nivel_id: 1,
    avaliar_nivel: false,
    ultima_fase: false,
    etapa_id: 3,
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Este momento é destinado a aprendizagem dos conceitos que serão trabalhados neste nível. Leia atentamente as definições e ouça os exemplos quantas vezes achar necessário',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Um dos parâmetros vocais avaliados na APA é o Grau Geral de alteração (G). Este parâmetro avalia o alteração vocal de forma global e pode ser classificado como normal, leve, moderado ou intenso.',
            'Para a classificação deste parâmetro utilizamos escalas da seguinte maneira:',
            '{escala_numerica}',
          ],
        },
      ],
      escala: [
        'Corresponde a ausência de Rugosidade (normalidade)',
        'Presença de Rugosidade leve',
        'Presença de Rugosidade moderado',
        'Presença de Rugosidade intenso',
      ],
      escala_titulo: 'Rugosidade',
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      titulo: 'Avaliação Inicial',
      categorias: [{ nome: 'rugosidade', descricao: 'Rugosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/training',
      descricoes: ['Classifique o Grau de Rugosidade das vozes a seguir'],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      categorias: [{ nome: 'rugosidade', descricao: 'Rugosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      categorias: [{ nome: 'rugosidade', descricao: 'Rugosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/concepts',
    },
  },
  {
    id: 11,
    nivel_id: 1,
    avaliar_nivel: false,
    ultima_fase: false,
    etapa_id: 3,
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Este momento é destinado a aprendizagem dos conceitos que serão trabalhados neste nível. Leia atentamente as definições e ouça os exemplos quantas vezes achar necessário',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Um dos parâmetros vocais avaliados na APA é o Grau Geral de alteração (G). Este parâmetro avalia o alteração vocal de forma global e pode ser classificado como normal, leve, moderado ou intenso.',
            'Para a classificação deste parâmetro utilizamos escalas da seguinte maneira:',
            '{escala_numerica}',
          ],
        },
      ],
      escala: [
        'Corresponde a ausência de Rugosidade (normalidade)',
        'Presença de Rugosidade leve',
        'Presença de Rugosidade moderado',
        'Presença de Rugosidade intenso',
      ],
      escala_titulo: 'Rugosidade',
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      titulo: 'Avaliação Inicial',
      categorias: [{ nome: 'rugosidade', descricao: 'Rugosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/training',
      descricoes: ['Classifique o Grau de Rugosidade das vozes a seguir'],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      categorias: [{ nome: 'rugosidade', descricao: 'Rugosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      categorias: [{ nome: 'rugosidade', descricao: 'Rugosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/concepts',
    },
  },
  {
    id: 12,
    nivel_id: 1,
    avaliar_nivel: true,
    ultima_fase: false,
    etapa_id: 3,
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Este momento é destinado a aprendizagem dos conceitos que serão trabalhados neste nível. Leia atentamente as definições e ouça os exemplos quantas vezes achar necessário',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Um dos parâmetros vocais avaliados na APA é o Grau Geral de alteração (G). Este parâmetro avalia o alteração vocal de forma global e pode ser classificado como normal, leve, moderado ou intenso.',
            'Para a classificação deste parâmetro utilizamos escalas da seguinte maneira:',
            '{escala_numerica}',
          ],
        },
      ],
      escala: [
        'Corresponde a ausência de Rugosidade (normalidade)',
        'Presença de Rugosidade leve',
        'Presença de Rugosidade moderado',
        'Presença de Rugosidade intenso',
      ],
      escala_titulo: 'Rugosidade',
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      titulo: 'Avaliação Inicial',
      categorias: [{ nome: 'rugosidade', descricao: 'Rugosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/training',
      descricoes: ['Classifique o Grau de Rugosidade das vozes a seguir'],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      categorias: [{ nome: 'rugosidade', descricao: 'Rugosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      categorias: [{ nome: 'rugosidade', descricao: 'Rugosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/concepts',
    },
  },
  {
    id: 13,
    nivel_id: 1,
    avaliar_nivel: false,
    ultima_fase: false,
    etapa_id: 3,
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Este momento é destinado a aprendizagem dos conceitos que serão trabalhados neste nível. Leia atentamente as definições e ouça os exemplos quantas vezes achar necessário',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Um dos parâmetros vocais avaliados na APA é o Grau de Soprosidade (B). Este parâmetro avalia o a presença de ar durante a emissão vocal. Pode ser classificado como normal, leve, moderado ou intenso.',
            'Para a classificação deste parâmetro utilizamos escalas da seguinte maneira:',
            '{escala_numerica}',
          ],
        },
      ],
      escala: [
        'Corresponde a ausência de Soprosidade (normalidade)',
        'Presença de Soprosidade leve',
        'Presença de Soprosidade moderado',
        'Presença de Soprosidade intenso',
      ],
      escala_titulo: 'Soprosidade',
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      titulo: 'Avaliação Inicial',
      categorias: [{ nome: 'soprosidade', descricao: 'Soprosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/training',
      descricoes: ['Classifique o Grau de Soprosidade das vozes a seguir'],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      categorias: [{ nome: 'soprosidade', descricao: 'Soprosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      categorias: [{ nome: 'soprosidade', descricao: 'Soprosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/concepts',
    },
  },
  {
    id: 14,
    nivel_id: 1,
    avaliar_nivel: false,
    ultima_fase: false,
    etapa_id: 3,
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Este momento é destinado a aprendizagem dos conceitos que serão trabalhados neste nível. Leia atentamente as definições e ouça os exemplos quantas vezes achar necessário',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Um dos parâmetros vocais avaliados na APA é o Grau de Soprosidade (B). Este parâmetro avalia o a presença de ar durante a emissão vocal. Pode ser classificado como normal, leve, moderado ou intenso.',
            'Para a classificação deste parâmetro utilizamos escalas da seguinte maneira:',
            '{escala_numerica}',
          ],
        },
      ],
      escala: [
        'Corresponde a ausência de Soprosidade (normalidade)',
        'Presença de Soprosidade leve',
        'Presença de Soprosidade moderado',
        'Presença de Soprosidade intenso',
      ],
      escala_titulo: 'Soprosidade',
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      titulo: 'Avaliação Inicial',
      categorias: [{ nome: 'soprosidade', descricao: 'Soprosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/training',
      descricoes: ['Classifique o Grau de Soprosidade das vozes a seguir'],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      categorias: [{ nome: 'soprosidade', descricao: 'Soprosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      categorias: [{ nome: 'soprosidade', descricao: 'Soprosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/concepts',
    },
  },
  {
    id: 15,
    nivel_id: 1,
    avaliar_nivel: false,
    ultima_fase: false,
    etapa_id: 3,
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Este momento é destinado a aprendizagem dos conceitos que serão trabalhados neste nível. Leia atentamente as definições e ouça os exemplos quantas vezes achar necessário',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Um dos parâmetros vocais avaliados na APA é o Grau de Soprosidade (B). Este parâmetro avalia o a presença de ar durante a emissão vocal. Pode ser classificado como normal, leve, moderado ou intenso.',
            'Para a classificação deste parâmetro utilizamos escalas da seguinte maneira:',
            '{escala_numerica}',
          ],
        },
      ],
      escala: [
        'Corresponde a ausência de Soprosidade (normalidade)',
        'Presença de Soprosidade leve',
        'Presença de Soprosidade moderado',
        'Presença de Soprosidade intenso',
      ],
      escala_titulo: 'Soprosidade',
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      titulo: 'Avaliação Inicial',
      categorias: [{ nome: 'soprosidade', descricao: 'Soprosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/training',
      descricoes: ['Classifique o Grau de Soprosidade das vozes a seguir'],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      categorias: [{ nome: 'soprosidade', descricao: 'Soprosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      categorias: [{ nome: 'soprosidade', descricao: 'Soprosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/concepts',
    },
  },
  {
    id: 16,
    nivel_id: 1,
    avaliar_nivel: true,
    ultima_fase: false,
    etapa_id: 3,
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Este momento é destinado a aprendizagem dos conceitos que serão trabalhados neste nível. Leia atentamente as definições e ouça os exemplos quantas vezes achar necessário',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Um dos parâmetros vocais avaliados na APA é o Grau de Soprosidade (B). Este parâmetro avalia o a presença de ar durante a emissão vocal. Pode ser classificado como normal, leve, moderado ou intenso.',
            'Para a classificação deste parâmetro utilizamos escalas da seguinte maneira:',
            '{escala_numerica}',
          ],
        },
      ],
      escala: [
        'Corresponde a ausência de Soprosidade (normalidade)',
        'Presença de Soprosidade leve',
        'Presença de Soprosidade moderado',
        'Presença de Soprosidade intenso',
      ],
      escala_titulo: 'Soprosidade',
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      titulo: 'Avaliação Inicial',
      categorias: [{ nome: 'soprosidade', descricao: 'Soprosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/training',
      descricoes: ['Classifique o Grau de Soprosidade das vozes a seguir'],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      categorias: [{ nome: 'soprosidade', descricao: 'Soprosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      categorias: [{ nome: 'soprosidade', descricao: 'Soprosidade' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/concepts',
    },
  },
  {
    id: 17,
    nivel_id: 1,
    avaliar_nivel: false,
    ultima_fase: false,
    etapa_id: 3,
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Este momento é destinado a aprendizagem dos conceitos que serão trabalhados neste nível. Leia atentamente as definições e ouça os exemplos quantas vezes achar necessário',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Um dos parâmetros vocais avaliados na APA é o Grau de Tensão (S). Este parâmetro avalia o a presença de esforço durante a emissão vocal. Pode ser classificado como normal, leve, moderado ou intenso.',
            'Para a classificação deste parâmetro utilizamos escalas da seguinte maneira:',
            '{escala_numerica}',
          ],
        },
      ],
      escala: [
        'Corresponde a ausência de Tensão (normalidade)',
        'Presença de Tensão leve',
        'Presença de Tensão moderado',
        'Presença de Tensão intenso',
      ],
      escala_titulo: 'Tensão',
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      titulo: 'Avaliação Inicial',
      categorias: [{ nome: 'tensao', descricao: 'Tensão' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/training',
      descricoes: ['Classifique o Grau de Tensão das vozes a seguir'],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      categorias: [{ nome: 'tensao', descricao: 'Tensão' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      categorias: [{ nome: 'tensao', descricao: 'Tensão' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/concepts',
    },
  },
  {
    id: 18,
    nivel_id: 1,
    avaliar_nivel: false,
    ultima_fase: false,
    etapa_id: 3,
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Este momento é destinado a aprendizagem dos conceitos que serão trabalhados neste nível. Leia atentamente as definições e ouça os exemplos quantas vezes achar necessário',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Um dos parâmetros vocais avaliados na APA é o Grau de Tensão (S). Este parâmetro avalia o a presença de esforço durante a emissão vocal. Pode ser classificado como normal, leve, moderado ou intenso.',
            'Para a classificação deste parâmetro utilizamos escalas da seguinte maneira:',
            '{escala_numerica}',
          ],
        },
      ],
      escala: [
        'Corresponde a ausência de Tensão (normalidade)',
        'Presença de Tensão leve',
        'Presença de Tensão moderado',
        'Presença de Tensão intenso',
      ],
      escala_titulo: 'Tensão',
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      titulo: 'Avaliação Inicial',
      categorias: [{ nome: 'tensao', descricao: 'Tensão' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/training',
      descricoes: ['Classifique o Grau de Tensão das vozes a seguir'],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      categorias: [{ nome: 'tensao', descricao: 'Tensão' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      categorias: [{ nome: 'tensao', descricao: 'Tensão' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/concepts',
    },
  },
  {
    id: 19,
    nivel_id: 1,
    avaliar_nivel: false,
    ultima_fase: false,
    etapa_id: 3,
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Este momento é destinado a aprendizagem dos conceitos que serão trabalhados neste nível. Leia atentamente as definições e ouça os exemplos quantas vezes achar necessário',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Um dos parâmetros vocais avaliados na APA é o Grau de Tensão (S). Este parâmetro avalia o a presença de esforço durante a emissão vocal. Pode ser classificado como normal, leve, moderado ou intenso.',
            'Para a classificação deste parâmetro utilizamos escalas da seguinte maneira:',
            '{escala_numerica}',
          ],
        },
      ],
      escala: [
        'Corresponde a ausência de Tensão (normalidade)',
        'Presença de Tensão leve',
        'Presença de Tensão moderado',
        'Presença de Tensão intenso',
      ],
      escala_titulo: 'Tensão',
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      titulo: 'Avaliação Inicial',
      categorias: [{ nome: 'tensao', descricao: 'Tensão' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/training',
      descricoes: ['Classifique o Grau de Tensão das vozes a seguir'],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      categorias: [{ nome: 'tensao', descricao: 'Tensão' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      categorias: [{ nome: 'tensao', descricao: 'Tensão' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/concepts',
    },
  },
  {
    id: 20,
    nivel_id: 1,
    avaliar_nivel: true,
    ultima_fase: true,
    etapa_id: 3,
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Este momento é destinado a aprendizagem dos conceitos que serão trabalhados neste nível. Leia atentamente as definições e ouça os exemplos quantas vezes achar necessário',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Um dos parâmetros vocais avaliados na APA é o Grau de Tensão (S). Este parâmetro avalia o a presença de esforço durante a emissão vocal. Pode ser classificado como normal, leve, moderado ou intenso.',
            'Para a classificação deste parâmetro utilizamos escalas da seguinte maneira:',
            '{escala_numerica}',
          ],
        },
      ],
      escala: [
        'Corresponde a ausência de Tensão (normalidade)',
        'Presença de Tensão leve',
        'Presença de Tensão moderado',
        'Presença de Tensão intenso',
      ],
      escala_titulo: 'Tensão',
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      titulo: 'Avaliação Inicial',
      categorias: [{ nome: 'tensao', descricao: 'Tensão' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/training',
      descricoes: ['Classifique o Grau de Tensão das vozes a seguir'],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      categorias: [{ nome: 'tensao', descricao: 'Tensão' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      categorias: [{ nome: 'tensao', descricao: 'Tensão' }],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/concepts',
    },
  },
  {
    id: 21,
    nivel_id: 2,
    avaliar_nivel: true,
    ultima_fase: true,
    etapa_id: 4,
    apresentacao: {
      titulo: 'ETAPA 4 – Avaliação simultânea dos parâmetros',
      descricoes: [
        'Nesta etapa você será treinado para classificar os graus de alterações do desvio vocal simultaneamente',
        'Esta etapa possui 4 FASES de treinamento. Você precisará ser aprovado nestas fases para poder completar o seu treinamento.',
      ],
      proximo: '/concepts',
    },
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Este momento é destinado a aprendizagem dos conceitos que serão trabalhados neste nível. Leia atentamente as definições e ouça os exemplos quantas vezes achar necessário',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Os principais parâmetros vocais avaliados na APA são o Grau Geral de alteração (G),  Grau de Rugosidade (R), Grau de Soprosidade (B) e Grau de Tensão (S).',
            'Os parâmetros R B S podem estar presentes simultaneamente na voz avaliada em diferentes graus ou no mesmo grau de alteração.',
            'As vozes também podem apresentar esses graus de forma isolada. Vozes apenas Rugosas, apenas soprosas ou apenas Tensas.',
          ],
        },
      ],
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      predominios: ['normal', 'rugosa', 'soprosa', 'tensa'],
      titulo: 'Avaliação Inicial',
      categorias: [
        { nome: 'grau_geral', descricao: 'Grau Geral' },
        { nome: 'rugosidade', descricao: 'Rugosidade' },
        { nome: 'soprosidade', descricao: 'Soprosidade' },
        { nome: 'tensao', descricao: 'Tensão' },
      ],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/training',
      descricoes: [
        'Classifique o predomínio e os graus dos parâmetros das vozes a seguir',
      ],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      categorias: ['Grau Geral', 'Rugosidade', 'Soprosidade', 'Tensão'],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      predominio: ['Normal', 'Rugosa', 'Soprosa', 'Tensa'],
      categorias: ['Grau Geral', 'Rugosidade', 'Soprosidade', 'Tensão'],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/concepts',
    },
  },
  {
    id: 22,
    nivel_id: 3,
    avaliar_nivel: false,
    ultima_fase: false,
    etapa_id: 4,
    apresentacao: {
      titulo: 'ETAPA 4 – Avaliação simultânea dos parâmetros',
      descricoes: [
        'Nesta etapa você será treinado para classificar os graus de alterações do desvio vocal simultaneamente',
        'Esta etapa possui 4 FASES de treinamento. Você precisará ser aprovado nestas fases para poder completar o seu treinamento.',
      ],
      proximo: '/concepts',
    },
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Este momento é destinado a aprendizagem dos conceitos que serão trabalhados neste nível. Leia atentamente as definições e ouça os exemplos quantas vezes achar necessário',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Os principais parâmetros vocais avaliados na APA são o Grau Geral de alteração (G),  Grau de Rugosidade (R), Grau de Soprosidade (B) e Grau de Tensão (S).',
            'Os parâmetros R B S podem estar presentes simultaneamente na voz avaliada em diferentes graus ou no mesmo grau de alteração.',
            'As vozes também podem apresentar esses graus de forma isolada. Vozes apenas Rugosas, apenas soprosas ou apenas Tensas.',
          ],
        },
      ],
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      predominios: ['normal', 'rugosa', 'soprosa', 'tensa'],
      titulo: 'Avaliação Inicial',
      categorias: [
        { nome: 'grau_geral', descricao: 'Grau Geral' },
        { nome: 'rugosidade', descricao: 'Rugosidade' },
        { nome: 'soprosidade', descricao: 'Soprosidade' },
        { nome: 'tensao', descricao: 'Tensão' },
      ],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/training',
      descricoes: [
        'Classifique o predomínio e os graus dos parâmetros das vozes a seguir',
      ],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      categorias: ['Grau Geral', 'Rugosidade', 'Soprosidade', 'Tensão'],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      predominio: ['Normal', 'Rugosa', 'Soprosa', 'Tensa'],
      categorias: ['Grau Geral', 'Rugosidade', 'Soprosidade', 'Tensão'],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/concepts',
    },
  },
  {
    id: 23,
    nivel_id: 3,
    avaliar_nivel: false,
    ultima_fase: false,
    etapa_id: 4,
    apresentacao: {
      titulo: 'ETAPA 4 – Avaliação simultânea dos parâmetros',
      descricoes: [
        'Nesta etapa você será treinado para classificar os graus de alterações do desvio vocal simultaneamente',
        'Esta etapa possui 4 FASES de treinamento. Você precisará ser aprovado nestas fases para poder completar o seu treinamento.',
      ],
      proximo: '/concepts',
    },
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Este momento é destinado a aprendizagem dos conceitos que serão trabalhados neste nível. Leia atentamente as definições e ouça os exemplos quantas vezes achar necessário',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Os principais parâmetros vocais avaliados na APA são o Grau Geral de alteração (G),  Grau de Rugosidade (R), Grau de Soprosidade (B) e Grau de Tensão (S).',
            'Os parâmetros R B S podem estar presentes simultaneamente na voz avaliada em diferentes graus ou no mesmo grau de alteração.',
            'As vozes também podem apresentar esses graus de forma isolada. Vozes apenas Rugosas, apenas soprosas ou apenas Tensas.',
          ],
        },
      ],
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      predominios: ['normal', 'rugosa', 'soprosa', 'tensa'],
      titulo: 'Avaliação Inicial',
      categorias: [
        { nome: 'grau_geral', descricao: 'Grau Geral' },
        { nome: 'rugosidade', descricao: 'Rugosidade' },
        { nome: 'soprosidade', descricao: 'Soprosidade' },
        { nome: 'tensao', descricao: 'Tensão' },
      ],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/training',
      descricoes: [
        'Classifique o predomínio e os graus dos parâmetros das vozes a seguir',
      ],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      categorias: ['Grau Geral', 'Rugosidade', 'Soprosidade', 'Tensão'],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      predominio: ['Normal', 'Rugosa', 'Soprosa', 'Tensa'],
      categorias: ['Grau Geral', 'Rugosidade', 'Soprosidade', 'Tensão'],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/concepts',
    },
  },
  {
    id: 24,
    nivel_id: 3,
    avaliar_nivel: false,
    ultima_fase: true,
    etapa_id: 4,
    apresentacao: {
      titulo: 'ETAPA 4 – Avaliação simultânea dos parâmetros',
      descricoes: [
        'Nesta etapa você será treinado para classificar os graus de alterações do desvio vocal simultaneamente',
        'Esta etapa possui 4 FASES de treinamento. Você precisará ser aprovado nestas fases para poder completar o seu treinamento.',
      ],
      proximo: '/concepts',
    },
    conceitos: {
      conteudos: [
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Este momento é destinado a aprendizagem dos conceitos que serão trabalhados neste nível. Leia atentamente as definições e ouça os exemplos quantas vezes achar necessário',
          ],
        },
        {
          titulo: 'Aprendizagem de Conceitos',
          corpo: [
            'Os principais parâmetros vocais avaliados na APA são o Grau Geral de alteração (G),  Grau de Rugosidade (R), Grau de Soprosidade (B) e Grau de Tensão (S).',
            'Os parâmetros R B S podem estar presentes simultaneamente na voz avaliada em diferentes graus ou no mesmo grau de alteração.',
            'As vozes também podem apresentar esses graus de forma isolada. Vozes apenas Rugosas, apenas soprosas ou apenas Tensas.',
          ],
        },
      ],
      proximo: '/classification',
      anterior: '/presentation',
    },
    classificacao_inicial: {
      predominios: ['normal', 'rugosa', 'soprosa', 'tensa'],
      titulo: 'Avaliação Inicial',
      categorias: [
        { nome: 'grau_geral', descricao: 'Grau Geral' },
        { nome: 'rugosidade', descricao: 'Rugosidade' },
        { nome: 'soprosidade', descricao: 'Soprosidade' },
        { nome: 'tensao', descricao: 'Tensão' },
      ],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/training',
      descricoes: [
        'Classifique o predomínio e os graus dos parâmetros das vozes a seguir',
      ],
    },
    treino: {
      apresentacao: {
        titulo: 'Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/training/classification',
      },
      descricoes: [
        'Classifique as vozes a seguir e observe o feedback da sua avaliação.',
      ],
      titulo: 'Treinamento',
      categorias: ['Grau Geral', 'Rugosidade', 'Soprosidade', 'Tensão'],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/final',
    },
    classificacao_final: {
      apresentacao: {
        titulo: 'Avaliação do Efeito do Treinamento',
        descricoes: [
          'Chegou o momento de treinar sua habilidade para detectar a presença de desvio vocal',
        ],
        proximo: '/classification',
      },
      titulo: 'Avaliação do Efeito do Treinamento',
      predominio: ['Normal', 'Rugosa', 'Soprosa', 'Tensa'],
      categorias: ['Grau Geral', 'Rugosidade', 'Soprosidade', 'Tensão'],
      graus: ['normal', 'leve', 'moderado', 'intenso'],
      proximo: '/report',
      descricoes: [
        'Classifique as vozes a seguir. Agora você não terá feedback! Em caso de dúvida consulte a ajuda',
      ],
    },
    report: {
      proximo: '/concepts',
    },
  },
];
