import React, { useState, useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import { PlayCircleFilled, PauseCircleFilled } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

const useAudio = (url) => {
  const [audio, setAudio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    setAudio(new Audio(url));
  }, [url]);

  useEffect(() => {
    // eslint-disable-next-line
    playing ? audio.play() : audio.pause();
  }, [playing]); // eslint-disable-line

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, [audio]);

  return [playing, toggle];
};

const Player = ({ url, ...props }) => {
  const classes = useStyles();
  const [playing, toggle] = useAudio(url);

  return (
    <>
      {playing ? (
        <IconButton onClick={toggle} color="primary" {...props}>
          <PauseCircleFilled className={classes.icon} />
        </IconButton>
      ) : (
        <IconButton onClick={toggle} color="primary" {...props}>
          <PlayCircleFilled className={classes.icon} />
        </IconButton>
      )}
    </>
  );
};

export default Player;

Player.propTypes = {
  url: PropTypes.string.isRequired,
};
