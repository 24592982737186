import React, { createContext, useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import api from '~/services/api';

const UsersContext = createContext({});

export const UsersProvider = ({ children }) => {
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
    nome: null,
    email: null,
  });

  const [results, setResults] = useState({
    count: 0,
    rows: [],
    page: 1,
    limit: 10,
    totalPages: 0,
    nextPage: null,
    previousPage: null,
  });

  const getUsers = useCallback(async () => {
    // Implementar a lógica para buscar usuários
    const response = await api.get('/users', { params: query });
    setResults(response.data);
  }, [query]);

  const setUsersQuery = useCallback(
    (params) => {
      setQuery((prevState) => ({ ...prevState, ...params }));
    },
    [setQuery]
  );

  const putUserAdmin = useCallback(async (id, is_admin) => {
    try {
      await api.put(`/user/${id}`, { is_admin });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <UsersContext.Provider
      value={{
        results,
        query,
        setUsersQuery,
        getUsers,
        putUserAdmin,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

UsersProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export function useUsers() {
  const context = useContext(UsersContext);

  return context;
}
