export const etapas = [
  {
    id: 1,
    nome: 'Etapa 1 - Presença e ausência de desvio vocal',
  },
  {
    id: 2,
    nome: 'Etapa 2 - Predomínio da qualidade vocal',
  },
  {
    id: 3,
    nome: 'Etapa 3 - Avaliação isolada dos parâmetros',
  },
  {
    id: 4,
    nome: 'Etapa 4 - Avaliação simultânea dos parâmetros',
  },
];
