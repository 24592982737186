export default {
  pem: `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAxPMvDViE/2q6PwAfXeNL
azN3CFRrRLpHMm7b9ttJFDIMBVJWnP99GTNDafpsJ9R+GRAlY3EEM8VIIC8BQX+O
Nj3VsgGLHqoXLG7KLe7PYFdo9XNSbO9WjWWkPGzS66tYIHM1+pvhsDTpGa+ddV/T
8QqhR7ddm20gBDoWLoE68k10ztohYVSKwjBwYwJGT2/hVeu0nLNtIBl1Z6Uw5ro2
G56mTDq7DRmBEFxunWFIEX9MjFrOnCkAgDj1RuhxJxc9ix4u8kvBfgGULi3ppajl
1hFoLtnr0i3gSK9m6Ju128uafJpjjRc8a67+eqtH88E3y4VarepZ4j5oVRK/gdJ2
WefPkNeeJahIkYPumCRXaqoto3mnTi+p8bmqJI4uHRkt3k9m/sc7jPLVNNwwuEH9
0N888bPM97X64WC42+vj+fkNEl6++HNDWMNpXsdF/3Mpyd+5Co9kTaQpxp4iuCtU
1rIHAdwca9DDYISuzNNsRvxf9zwxyEJ6ExNjBJsECzCFymmmDfEv8nBrTwS3DGIv
+o11hLECc6qfLo8jWZicj0f2l18a3DdAo5pzgXWqAYjoK0GYpmb3/GHSxbVYFsRT
Vg7FylPO2kL1IrLa/fhJucI+9+5aDQit4Ng29XKCVjWo/1faUt6ThWMMOcRFDcZp
Tyjake1WbvwEpCegzpeCmXcCAwEAAQ==
-----END PUBLIC KEY-----`,
};
