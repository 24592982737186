import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Paper,
  Typography,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Button,
  Fade,
} from '@material-ui/core';

import Concept from '~/components/Concept';

import { useStyles } from './styles';
import { useDatabase } from '~/hooks/database';
import { useAuth } from '~/hooks/auth';

import history from '~/services/history';

export default function Concepts() {
  const { id } = useParams();
  const classes = useStyles();
  const { select } = useDatabase();
  const { updateUser } = useAuth();
  const [loaded, setLoaded] = useState(false);
  const [data] = useState(select('fases', id));
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded]);

  const handleNext = async () => {
    if (activeStep === data.conceitos.conteudos.length - 1) {
      await updateUser({
        proximo: `${data.conceitos.proximo}/${id}`,
      });

      history.push(`${data.conceitos.proximo}/${id}`);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = async () => {
    if (activeStep === 0) {
      await updateUser({
        proximo: `${data.conceitos.anterior}/${id}`,
      });
      history.push(`${data.conceitos.anterior}/${id}`);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (
    <Paper>
      <Fade in={loaded} timeout={1000}>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="h5">
              ETAPA {data.etapa_id} - FASE {data.id}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {data.conceitos.conteudos.map((conteudo, key) => (
                <Step key={`${Math.random(0, 10 - key)}`}>
                  <StepLabel>{conteudo.titulo}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12}>
            <Concept concepts={data.conceitos.conteudos[activeStep]} />
          </Grid>
          <Grid item xs={12} className={classes.buttons}>
            <Button onClick={handleBack} color="primary" variant="contained">
              VOLTAR
            </Button>
            <Button variant="contained" color="primary" onClick={handleNext}>
              PRÓXIMO
            </Button>
          </Grid>
        </Grid>
      </Fade>
    </Paper>
  );
}
