import React, { createContext, useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import api from '~/services/api';

const ResultsContext = createContext({});

export const ResultsProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const [results, setResults] = useState(null);

  const getResults = useCallback(async () => {
    try {
      const response = await api.get(`/users/${user.id}/results`);
      setResults(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [user]); // eslint-disable-line

  return (
    <ResultsContext.Provider
      value={{
        user,
        setUser,
        getResults,
        results,
      }}
    >
      {children}
    </ResultsContext.Provider>
  );
};

ResultsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export function useResults() {
  const context = useContext(ResultsContext);

  return context;
}
