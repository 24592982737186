import React, { useEffect } from 'react';
import { Paper } from '@material-ui/core';

import { useAuth } from '~/hooks/auth';
// import { useStyles } from './styles';

export default function Dashboard() {
  // const classes = useStyles();
  const { signOut } = useAuth();

  useEffect(() => {
    if (window.location.search) {
      signOut();
    }
  }, [signOut]);

  return <Paper>Dashboard</Paper>;
}
