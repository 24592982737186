import React, { useRef, useEffect, useState } from 'react';
import { useField } from '@unform/core';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

function CustomTextField({ name, defaultValue, InputLabelProps, ...rest }) {
  const inputRef = useRef(null);
  const {
    fieldName,
    registerField,
    defaultValue: defaultFieldValue,
    error,
  } = useField(name);
  const defaultInputValue = defaultValue || defaultFieldValue;

  const [shrink, setShrink] = useState(!!defaultInputValue);

  useEffect(() => {
    if (inputRef.current) {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'value',
        clearValue: (ref, resetValue) => {
          const newValue = resetValue || defaultInputValue || '';
          ref.value = newValue;
          setShrink(!!newValue);
        },
        setValue(ref, value) {
          if (ref) {
            const newValue = value || '';
            ref.value = newValue;
            setShrink(!!newValue);
          }
        },
      });
    }
  }, [fieldName, registerField]); //eslint-disable-line

  useEffect(() => {
    const input = inputRef.current;

    function handlerFocusEvent(evt) {
      const inputValue = evt.currentTarget.value;
      if (!inputValue) setShrink(true);
    }

    function handlerBlurEvent(evt) {
      const inputValue = evt.target.value;
      if (!inputValue) setShrink(false);
    }

    if (input) {
      input.addEventListener('focus', handlerFocusEvent);
      input.addEventListener('blur', handlerBlurEvent);
    }

    return () => {
      if (input) {
        input.removeEventListener('focus', handlerFocusEvent);
        input.removeEventListener('blur', handlerBlurEvent);
      }
    };
  }, [inputRef]);

  return (
    <TextField
      {...rest}
      inputRef={inputRef}
      defaultValue={defaultInputValue}
      error={!!error}
      helperText={error}
      name={name}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: (InputLabelProps && InputLabelProps.shrink) || shrink,
      }}
    />
  );
}

CustomTextField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any, //eslint-disable-line
  InputLabelProps: PropTypes.shape(), //eslint-disable-line
};

CustomTextField.defaultProps = {
  defaultValue: undefined,
};

export default React.memo(CustomTextField);
