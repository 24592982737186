import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Grid, Fade, Paper, Button } from '@material-ui/core';

import { useStyles } from './styles';
import { useDatabase } from '~/hooks/database';
import { useAuth } from '~/hooks/auth';

import history from '~/services/history';

export default function Training() {
  const classes = useStyles();
  const { id } = useParams();
  const { select } = useDatabase();
  const { updateUser } = useAuth();

  const [loaded, setLoaded] = useState(false);
  const [data] = useState(select('fases', id));

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded]);

  const handleNext = async () => {
    await updateUser({
      proximo: `${data.treino.apresentacao.proximo}/${id}`,
    });
    history.push(`${data.treino.apresentacao.proximo}/${id}`);
  };

  return (
    <Paper>
      <Fade in={loaded} timeout={1000}>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="h5">
              ETAPA {data.etapa_id} - FASE {data.id}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              {data.treino.apresentacao.titulo}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {data.treino.apresentacao.descricoes.map((descricao) => (
              <Typography
                variant="h6"
                align="center"
                key={descricao.substr(0, 10)}
              >
                {descricao}
              </Typography>
            ))}
          </Grid>
          <Grid item xs={12} className={classes.next}>
            <Button variant="contained" color="primary" onClick={handleNext}>
              AVANÇAR
            </Button>
          </Grid>
        </Grid>
      </Fade>
    </Paper>
  );
}
