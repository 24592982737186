import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    margin: '15px 0',
  },
  player: {
    display: 'flex',
    justifyContent: 'center',
  },
  next: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));
