import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxHeight: '90vh',
    overflow: 'auto',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    margin: theme.spacing(3, 0, 1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  title: {
    color: 'rgb(23, 43, 77)',
  },
  subtitle: {
    color: 'rgb(107, 119, 140)',
  },
  logo: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  marginNormal: {
    marginTop: '16px',
    marginBottom: '8px',
  },
}));
