import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Grid, Fade, Paper, Button } from '@material-ui/core';

import { useStyles } from './styles';
import { useDatabase } from '~/hooks/database';
import { useAuth } from '~/hooks/auth';
import { useClassification } from '~/hooks/classification';

import history from '~/services/history';

export default function Report() {
  const { id } = useParams();
  const { user, updateUser } = useAuth();
  const classes = useStyles();
  const { select } = useDatabase();
  const { fase } = useClassification();

  const [loaded, setLoaded] = useState(false);
  const [data] = useState(select('fases', id));
  const [nivel, setNivel] = useState(null);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded]); // eslint-disable-line

  useEffect(() => {
    if (data) {
      setNivel(select('niveis', data.nivel_id));
    }
  }, [data, id]); // eslint-disable-line

  const getProximo = (nextId, reload) =>
    reload
      ? `/presentation/${nextId}/?reload=true`
      : `/presentation/${nextId}/?reload=true`;

  const handleNext = async () => {
    let proximo = null;
    let { experiencia } = user;

    if (id === 21) {
      if (!fase.aprovado) {
        if (Number(user.experiencia) === 3) {
          experiencia = 2;
          proximo = getProximo(id);
        }

        if (Number(user.experiencia) === 2) {
          experiencia = 1;
          proximo = getProximo(id);
        }

        if (Number(user.experiencia) === 1) {
          proximo = getProximo(3);
        }
      } else {
        if (Number(user.experiencia) === 3) {
          proximo = getProximo(Number(id) + 1);
        }

        if (Number(user.experiencia) === 2) {
          experiencia = 3;
          proximo = getProximo(id);
        }

        if (Number(user.experiencia) === 1) {
          experiencia = 2;
          proximo = getProximo(id);
        }
      }
    } else if (!fase.aprovado) {
      proximo = getProximo(id, true);
    } else {
      proximo = getProximo(Number(id) + 1, false);
    }

    await updateUser({
      proximo,
      experiencia,
    });

    history.push(proximo);
  };

  const getResult = (initial, numbers) => (
    <>
      <Typography
        variant="body1"
        component="p"
        align="center"
        className={classes.property}
      >
        {initial ? 'Pré-treinamento' : 'Pós-treinamento'}
      </Typography>
      {[1, 2, 3, 4].includes(Number(id)) && (
        <>
          <Typography
            variant="body1"
            component="p"
            align="center"
            className={classes.result}
          >
            Acurácia:{' '}
            {numbers.acuracia <= 0
              ? 0
              : ((numbers.acuracia / 1) * 100).toFixed(0)}
            %
          </Typography>
          {[1, 2].includes(Number(id)) && (
            <>
              <Typography
                variant="body1"
                component="p"
                align="center"
                className={classes.result}
              >
                Sensibilidade:{' '}
                {numbers.sensibilidade <= 0
                  ? 0
                  : ((numbers.sensibilidade / 1) * 100).toFixed(0)}
                %
              </Typography>
              <Typography
                variant="body1"
                component="p"
                align="center"
                className={classes.result}
              >
                Especificidade:{' '}
                {numbers.especificidade <= 0
                  ? 0
                  : ((numbers.especificidade / 1) * 100).toFixed(0)}
                %
              </Typography>
            </>
          )}
          <Typography
            variant="body1"
            component="p"
            align="center"
            className={classes.result}
          >
            Concordância:{' '}
            {numbers.concordancia <= 0
              ? 0
              : ((numbers.concordancia / 1) * 100).toFixed(0)}
            %
          </Typography>
        </>
      )}
      {id >= 5 && (
        /*eslint-disable*/
        <>
          {Object.keys(numbers).map((propriedade) => {
            if (
              ['grau_geral', 'rugosidade', 'soprosidade', 'tensao'].includes(
                propriedade
              )
            ) {
              const tituloTexto =
                propriedade === 'grau_geral'
                  ? 'GRAU GERAL'
                  : propriedade.toUpperCase();

              return (
                <>
                  <Typography
                    variant="body1"
                    component="p"
                    align="center"
                    className={classes.property}
                  >
                    {tituloTexto}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    align="center"
                    className={classes.result}
                  >
                    Acurácia:{' '}
                    {numbers[propriedade].acuracia <= 0
                      ? 0
                      : ((numbers[propriedade].acuracia / 1) * 100).toFixed(0)}
                    %
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    align="center"
                    className={classes.result}
                  >
                    Concordância:{' '}
                    {numbers[propriedade].concordancia <= 0
                      ? 0
                      : ((numbers[propriedade].concordancia / 1) * 100).toFixed(
                          0
                        )}
                    %
                  </Typography>
                </>
              );
            }
          })}
        </>
      )}
    </>
  );

  return (
    <Paper>
      <Fade in={loaded} timeout={1000}>
        <Grid container className={classes.container} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">{data.apresentacao.titulo}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Relatório de Desempenho</Typography>
          </Grid>
          <Grid item xs={4} className={classes.cardParent}>
            <div className={classes.card}>
              {fase.resultadoInicial && getResult(true, fase.resultadoInicial)}
            </div>
          </Grid>
          <Grid item xs={4} className={classes.cardParent}>
            <div className={classes.card}>
              {fase.resultadoFinal &&
                getResult(false, fase.resultadoFinal.resultados)}
            </div>
          </Grid>
          <Grid item xs={4} className={classes.cardParent}>
            <div className={classes.card}>
              <Typography
                variant="body1"
                component="p"
                className={classes.title}
              >
                Resultado
              </Typography>
              {fase.resultadoFinal && nivel && (
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.title}
                >
                  {fase.aprovado
                    ? 'Parabéns! Você atingiu o índice. Continue assim! A próxima fase foi liberada.'
                    : 'Hmm você não atingiu o índice, mas não desanime. Repita o treinamento da fase para melhorar seu desempenho.'}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={12} className={classes.next}>
            <Button variant="contained" color="primary" onClick={handleNext}>
              AVANÇAR
            </Button>
          </Grid>
        </Grid>
      </Fade>
    </Paper>
  );
}
