import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import { Check } from '@material-ui/icons';
import clsx from 'clsx';

import { useStyles } from './styles';

export default function Classification({
  predominios,
  categorias,
  graus,
  addSelecao,
  selecionados,
  respostasDisponiveis,
  escolhasConfirmadas,
  audio,
  treino,
  audioAnswer,
}) {
  const classes = useStyles();
  const { id } = useParams();

  const checkSelected = (categoria, grau) =>
    selecionados.some(
      (selected) =>
        selected.categoriaSelected === categoria &&
        selected.grauSelected === grau
    );

  const getOptions = (categoriaOption) => {
    if (!categorias.length) {
      const useKey = id > 4;

      return (
        <>
          {graus.map((grau, key) => (
            <TableCell key={Math.random()}>
              <div className={classes.cell}>
                <ToggleButton
                  variant="contained"
                  value={grau}
                  selected={selecionados.includes(grau)}
                  color="primary"
                  onClick={() => addSelecao(null, grau)}
                  className={classes.toggle}
                  classes={{ selected: classes.selected }}
                  disabled={!respostasDisponiveis || escolhasConfirmadas}
                  key={grau}
                >
                  <Check />{' '}
                  {useKey ? key - grau.toUpperCase() : grau.toUpperCase()}
                </ToggleButton>
              </div>
            </TableCell>
          ))}
        </>
      );
    }

    if (!graus.length) {
      return (
        <>
          {categorias.map((categoria) => (
            <TableCell key={Math.random()}>
              <div className={classes.cell}>
                <ToggleButton
                  variant="contained"
                  value={categoria}
                  selected={selecionados.includes(categoria)}
                  color="primary"
                  onClick={() => addSelecao(categoria, null)}
                  className={classes.toggle}
                  classes={{ selected: classes.selected }}
                  disabled={!respostasDisponiveis || escolhasConfirmadas}
                  key={categoria}
                >
                  <Check /> {categoria.toUpperCase()}
                </ToggleButton>
              </div>
            </TableCell>
          ))}
        </>
      );
    }

    return (
      <>
        {graus.map((grau, key) => (
          <TableCell key={Math.random()}>
            <div className={classes.cell}>
              <ToggleButton
                variant="contained"
                value={grau}
                selected={checkSelected(categoriaOption, grau)}
                color="primary"
                onClick={() => addSelecao(categoriaOption, grau)}
                className={clsx(
                  classes.toggle,
                  treino &&
                    escolhasConfirmadas &&
                    audioAnswer &&
                    ((audioAnswer[categoriaOption].toUpperCase() ===
                      grau.toUpperCase() &&
                      classes.toggleSuccess) ||
                      classes.toggleError)
                )}
                classes={{ selected: classes.selected }}
                disabled={!respostasDisponiveis || escolhasConfirmadas}
                key={grau}
              >
                <Check /> {key} - {grau.toUpperCase()}
              </ToggleButton>
            </div>
          </TableCell>
        ))}
      </>
    );
  };

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {Boolean(predominios.length) && (
            <TableRow>
              <TableCell>PREDOMÍNIO</TableCell>
              {predominios.map((predominio) => (
                <TableCell key={Math.random()}>
                  <div className={classes.cell}>
                    <ToggleButton
                      variant="contained"
                      value={predominio}
                      selected={checkSelected('predominio', predominio)}
                      color="primary"
                      onClick={() => addSelecao('predominio', predominio)}
                      className={clsx(
                        classes.toggle,
                        treino &&
                          escolhasConfirmadas &&
                          ((audio.predominio === predominio &&
                            classes.toggleSuccess) ||
                            classes.toggleError)
                      )}
                      classes={{ selected: classes.selected }}
                      disabled={!respostasDisponiveis || escolhasConfirmadas}
                      key={predominio}
                    >
                      <Check /> {predominio.toUpperCase()}
                    </ToggleButton>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          )}
          {(categorias.length &&
            graus.length &&
            categorias.map((categoria) => (
              <TableRow key={Math.random()}>
                <TableCell>{categoria.descricao.toUpperCase()}</TableCell>
                {getOptions(categoria.nome)}
              </TableRow>
            ))) || <TableRow>{getOptions()}</TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

Classification.propTypes = {
  predominios: PropTypes.arrayOf(PropTypes.string),
  categorias: PropTypes.arrayOf(PropTypes.any),
  graus: PropTypes.arrayOf(PropTypes.string),
  selecionados: PropTypes.arrayOf(PropTypes.any).isRequired,
  addSelecao: PropTypes.func.isRequired,
  respostasDisponiveis: PropTypes.bool.isRequired,
  escolhasConfirmadas: PropTypes.bool,
  audio: PropTypes.shape(),
  treino: PropTypes.bool,
  audioAnswer: PropTypes.shape(),
};

Classification.defaultProps = {
  categorias: [],
  graus: [],
  predominios: [],
  escolhasConfirmadas: false,
  treino: false,
  audio: null,
  audioAnswer: null,
};
