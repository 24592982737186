import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  CssBaseline,
  Container,
  AppBar,
  Toolbar,
  Grid,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import { ExitToApp, People } from '@material-ui/icons';
import { ThemeProvider } from '@material-ui/core/styles';
import { ConfirmProvider } from 'material-ui-confirm';

import Loading from '~/components/Loading';
import history from '~/services/history';

import { useLoading } from '~/hooks/loading';
import { useAuth } from '~/hooks/auth';
import { useDatabase } from '~/hooks/database';

import systemTheme from '~/styles/theme';

import { useStyles } from './styles';

export default function Layout({ children }) {
  const classes = useStyles();
  const { id } = useParams();
  const { loadingShowed } = useLoading();
  const { signOut, user } = useAuth();
  const { select } = useDatabase();
  const fase = select('fases', id);
  const { pathname } = window.location;
  const userPath = pathname.includes('/user');

  const getNivel = () => {
    switch (user.experiencia) {
      case '2':
        return 'Intermediário';
      case '3':
        return 'Avançado';
      default:
        return 'Iniciante';
    }
  };

  const handleClickUsers = () => {
    history.push('/users');
  };

  return (
    <ThemeProvider theme={systemTheme(!userPath && fase ? fase.etapa_id : 0)}>
      <ConfirmProvider
        defaultOptions={{
          confirmationText: 'Confirmar',
          cancellationText: 'Cancelar',
          confirmationButtonProps: { variant: 'contained', color: 'primary' },
          cancellationButtonProps: { variant: 'contained', color: 'secondary' },
        }}
      >
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            variant="elevation"
            position="fixed"
            className={classes.appBar}
          >
            <Toolbar variant="dense">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <div>
                  <Typography>
                    {!userPath && user && `Nível: ${getNivel()}`}
                    {!userPath && fase && `| Etapa: ${fase.etapa_id} / 4`}
                    {userPath && 'Admin'}
                  </Typography>
                </div>
                <div>
                  <Typography className={classes.systemName}>
                    Ouvindo Vozes
                  </Typography>
                </div>
                <div className={classes.buttons}>
                  {user.is_admin && (
                    <IconButton color="inherit" onClick={handleClickUsers}>
                      <People />
                    </IconButton>
                  )}
                  <Button
                    variant="text"
                    color="inherit"
                    startIcon={<ExitToApp fontSize="large" />}
                    className={classes.exitButton}
                    onClick={() => signOut()}
                  >
                    SAIR
                  </Button>
                </div>
              </Grid>
            </Toolbar>
          </AppBar>
          <Container className={classes.content} maxWidth="lg">
            <div className={classes.toolbar} />
            {children}
            {loadingShowed && <Loading />}
          </Container>
        </div>
      </ConfirmProvider>
    </ThemeProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};
