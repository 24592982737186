import React, { useEffect, useState } from 'react';
import { Grid, Typography, ButtonGroup, Button, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

import history from '~/services/history';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 900,
    color: '#fff',
  },
  emphasis: {
    color: green[400],
  },
  subtitle: {
    color: '#fff',
  },
}));

export default function Initial() {
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded]);

  return (
    <Fade in={loaded} timeout={1000}>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h3" component="p" className={classes.title}>
            Bem vindo(a) ao sistema{' '}
            <span className={classes.emphasis}>Ouvindo Vozes</span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="p" className={classes.subtitle}>
            Simulador de treinamento para o julgamento perceptivo-auditivo da
            voz.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ButtonGroup>
            <Button
              color="primary"
              variant="contained"
              onClick={() => history.push('/signin')}
            >
              INICIAR
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => history.push('/guide')}
            >
              TUTORIAL
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => history.push('/credits')}
            >
              CRÉDITOS
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </Fade>
  );
}
