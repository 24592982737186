import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '400px',
    padding: theme.spacing(2),
  },
  player: {
    display: 'flex',
    justifyContent: 'center',
  },
  numberScale: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  concept: {
    margin: '10px 0',
  },
}));
