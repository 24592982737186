import axios from 'axios';
import history from '~/services/history';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      error.response.data &&
      error.response.data.message === 'session expired'
    ) {
      history.push('/dashboard?expired=true');
    }
    return Promise.reject(error);
  }
);

export default api;
