import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  message: {
    color: 'rgb(107, 119, 140)',
    margin: theme.spacing(2, 0),
  },
}));
