import { createTheme, useTheme } from '@material-ui/core/styles';
import {
  indigo,
  blue,
  red,
  deepOrange,
  deepPurple,
  teal,
} from '@material-ui/core/colors';

export default function SystemTheme(etapa) {
  const { palette } = useTheme();
  let primary = null;

  switch (etapa) {
    case 1:
      primary = {
        light: teal['200'],
        main: teal['500'],
        dark: teal['800'],
        contrastText: '#fff',
      };
      break;
    case 2:
      primary = {
        light: deepPurple['200'],
        main: deepPurple['500'],
        dark: deepPurple['800'],
        contrastText: '#fff',
      };
      break;
    case 3:
      primary = {
        light: deepOrange['200'],
        main: deepOrange['500'],
        dark: deepOrange['800'],
        contrastText: '#fff',
      };
      break;
    case 4:
      primary = {
        light: red['200'],
        main: red['500'],
        dark: red['800'],
        contrastText: '#fff',
      };
      break;
    default:
      primary = {
        light: blue['200'],
        main: blue['500'],
        dark: blue['800'],
        contrastText: '#fff',
      };
      break;
  }

  return createTheme({
    palette: {
      primary,
      secondary: {
        light: indigo['200'],
        main: indigo['500'],
        dark: indigo['800'],
        contrastText: '#fff',
      },
    },
    typography: {
      fontFamily: ['Lato', 'Roboto'].join(','),
    },
    overrides: {
      MuiTable: {
        root: {
          '& caption': {
            textAlign: 'center',
          },
        },
      },
      MuiTableCell: {
        root: {
          borderBottom: 0,
        },
        head: {
          background: primary.main,
          color: palette.primary.contrastText,
        },
      },
      MuiButton: {
        root: {
          margin: '0 1px',
          minWidth: '125px',
        },
        label: {
          textTransform: 'initial',
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: '16px',
        },
      },
      MuiPaper: {
        root: {
          boxShadow:
            'rgb(0 0 0 / 12%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 0px 0px 1px',
          transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
      },
      MuiCard: {
        root: {
          borderRadius: '16px',
          boxShadow:
            'rgb(0 0 0 / 12%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 0px 0px 1px',
          transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
      },
      MuiLink: {
        root: {
          cursor: 'pointer',
        },
      },
    },
  });
}
