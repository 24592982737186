import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Fade,
  Modal,
  Backdrop,
  IconButton,
  Grid,
  Typography,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { Help, Cancel } from '@material-ui/icons';

import { useStyles } from './styles';

import { audios } from '~/assets/audios';

import Player from '~/components/Player';

export default function InitialClassificationHelp({ categorias, graus }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getAudios = () => {
    if (!categorias || !categorias.length) {
      return (
        <>
          {graus.map((grau) => (
            <TableCell key={Math.random()} align="center">
              <Player
                url={audios[`voz_${grau}`][Math.floor(Math.random() * 3)]}
              />
              <Typography variant="body1" align="center">
                {grau.toUpperCase()}
              </Typography>
            </TableCell>
          ))}
        </>
      );
    }

    if (!graus || !graus.length) {
      return (
        <>
          {categorias.map((categoria) => (
            <TableCell key={Math.random()} align="center">
              <Player
                url={audios[`voz_${categoria}`][Math.floor(Math.random() * 3)]}
              />
              <Typography variant="body1" align="center">
                {categoria.toUpperCase()}
              </Typography>
            </TableCell>
          ))}
        </>
      );
    }

    return (
      <>
        {graus.map((grau) => (
          <TableCell key={Math.random()} align="center">
            <Player
              url={audios[`voz_${grau}`][Math.floor(Math.random() * 3)]}
            />
            <Typography variant="body1" align="center">
              {grau.toUpperCase()}
            </Typography>
          </TableCell>
        ))}
      </>
    );
  };

  return (
    <div>
      <Button
        variant="contained"
        endIcon={<Help />}
        onClick={handleOpen}
        color="primary"
      >
        AJUDA
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <IconButton
              onClick={handleClose}
              color="primary"
              className={classes.btClose}
              variant="contained"
            >
              <Cancel fontSize="large" color="primary" />
            </IconButton>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" align="center">
                  AJUDA
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" align="center">
                  Ouça o exemplo
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableBody>
                      {(categorias &&
                        categorias.length &&
                        graus &&
                        graus.length &&
                        categorias.map((categoria) => (
                          <TableRow key={Math.random()}>
                            <TableCell align="left">
                              {categoria.descricao.toUpperCase()}
                            </TableCell>
                            {getAudios()}
                          </TableRow>
                        ))) || <TableRow>{getAudios()}</TableRow>}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

InitialClassificationHelp.propTypes = {
  categorias: PropTypes.arrayOf(PropTypes.string),
  graus: PropTypes.arrayOf(PropTypes.string),
};

InitialClassificationHelp.defaultProps = {
  categorias: [],
  graus: [],
};
