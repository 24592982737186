export const user = {
  id: null,
  nome: null,
  idade: null,
  email: null,
  grupo: null,
  experiencia: null,
  periodo: null,
  horas_treinamento: null,
  cantor: null,
  tempo_cantor: null,
  graduado_musica: null,
  voz_normal: null,
  queixa_auditiva: null,
  tempo_disponivel: null,
  senha: null,
};
