import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '400px',
    padding: theme.spacing(2),
  },
  player: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  next: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  toggle: {
    minWidth: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  selected: {
    '&&': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    background: '#fff',
  },
  help: {
    display: 'flex',
    alignItems: 'center',
  },
  cell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  toggleSuccess: {
    border: `2px solid ${theme.palette.success.main}`,
  },
  toggleError: {
    border: `2px solid ${theme.palette.error.dark}`,
  },
}));
