import React, { useRef, useCallback, useState, useEffect } from 'react';
import {
  Button,
  Link,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  Fade,
  Paper,
} from '@material-ui/core';
import { Visibility, VisibilityOff, Lock } from '@material-ui/icons';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import TextField from '~/components/Utils/TextField';

import getValidationErrors from '~/utils/getValidationErrors';
import history from '~/services/history';
import { useAuth } from '~/hooks/auth';
import { useLoading } from '~/hooks/loading';

import { useStyles } from './styles';

export default function SignIn() {
  const classes = useStyles();
  const formRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const { signIn } = useAuth();
  const { loadingShowed, showLoading, hideLoading } = useLoading();

  const [showPassword, setShowPassword] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Precisamos de um email válido.')
            .required('Precisamos que informe e-mail cadastrado.'),
          password: Yup.string().required('A senha é obrigatória.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        showLoading();
        await signIn(data);
        hideLoading();
      } catch (err) {
        hideLoading();
        if (err.inner) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);

          return;
        }

        if (err.message === 'Network Error') {
          enqueueSnackbar(
            'Serviço temporareamente indisponível, tente novamente mais tarde.',
            { variant: 'info' }
          );

          return;
        }

        enqueueSnackbar('Usuário e/ou senha inválido(s)', { variant: 'error' });
      }
    },
    [enqueueSnackbar, signIn, showLoading, hideLoading]
  );

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Fade in={loaded} timeout={1000}>
      <Paper className={classes.paper}>
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          alignItems="center"
        >
          <Grid item xs={8}>
            <Typography component="h1" variant="h5" className={classes.title}>
              Entrar
            </Typography>
            <Typography
              component="p"
              variant="body2"
              className={classes.subtitle}
            >
              Entrar para acessar a plataforma
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.logo}>
            <Lock color="primary" fontSize="large" />
          </Grid>
        </Grid>
        <Form className={classes.form} ref={formRef} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            autoComplete="email"
            autoFocus
            color="primary"
            disabled={loadingShowed}
            size="small"
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Senha"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            color="primary"
            disabled={loadingShowed}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loadingShowed}
            size="small"
          >
            Acessar
          </Button>
        </Form>
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            history.push('/signup');
          }}
          color="inherit"
        >
          Não sou cadastrado
        </Link>
      </Paper>
    </Fade>
  );
}
