import React from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import history from '~/services/history';

import { useStyles } from './styles';
import { useResults } from '../../hooks/results';

function Results() {
  const classes = useStyles();
  const { results, getResults, user } = useResults();

  React.useEffect(() => {
    getResults();
  }, []); // eslint-disable-line

  const handleClickBack = () => {
    history.push('/users');
  };

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClickBack}
        className={classes.btBack}
      >
        Voltar
      </Button>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Typography variant="body1" className={classes.username}>
          Usuário: {user.nome}
        </Typography>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>Fase</TableCell>
              <TableCell align="right" className={classes.headerCell}>
                Teste Inicial
              </TableCell>
              <TableCell align="right" className={classes.headerCell}>
                Sensibiliddade
              </TableCell>
              <TableCell align="right" className={classes.headerCell}>
                Especificidade
              </TableCell>
              <TableCell align="right" className={classes.headerCell}>
                Acurácia
              </TableCell>
              <TableCell align="right" className={classes.headerCell}>
                Concordância
              </TableCell>
              <TableCell align="right" className={classes.headerCell}>
                Aprovado
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results &&
              results.map((result) => {
                const percentagens = JSON.parse(result.resultados);
                return (
                  <TableRow key={result.id}>
                    <TableCell component="th" scope="row">
                      {result.fase}
                    </TableCell>
                    <TableCell align="right">
                      {result.initial ? 'Sim' : 'Não'}
                    </TableCell>
                    <TableCell align="right">
                      {((percentagens.sensibilidade / 1) * 100).toFixed(0)}%
                    </TableCell>
                    <TableCell align="right">
                      {((percentagens.especificidade / 1) * 100).toFixed(0)}%
                    </TableCell>
                    <TableCell align="right">
                      {((percentagens.acuracia / 1) * 100).toFixed(0)}%
                    </TableCell>
                    <TableCell align="right">
                      {((percentagens.concordancia / 1) * 100).toFixed(0)}%
                    </TableCell>
                    <TableCell align="right">
                      {result.aprovado ? 'Sim' : 'Não'}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Results;
