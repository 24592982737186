import React from 'react';
import { Backdrop, Box, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useLoading } from '~/hooks/loading';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Loading() {
  const classes = useStyles();
  const { progress } = useLoading();

  return (
    <Backdrop className={classes.backdrop} open>
      <div>
        <Box position="relative" display="inline-flex">
          <CircularProgress
            variant={progress ? 'determinate' : 'indeterminate'}
            value={progress}
            color="primary"
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {progress && (
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
              >{`${Math.round(progress)}%`}</Typography>
            )}
          </Box>
        </Box>
      </div>
    </Backdrop>
  );
}
