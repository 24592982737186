import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  btBack: {
    maxWidth: '125px',
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    maxHeight: '90vh',
    overflow: 'auto',
  },
  headerCell: {
    color: '#111',
    fontSize: '1.1rem',
    fontWeight: '700',
  },
  filterContainer: {
    padding: theme.spacing(2),
  },
  gridBtFilter: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
