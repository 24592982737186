import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';

export default function CustomSwitch({ name, defaultChecked, ...rest }) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue } = useField(name);

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: fieldName,
        ref: ref.current,
        path: 'checked',
      });
    }
  }, [fieldName, registerField]); //eslint-disable-line

  return (
    <Switch
      inputRef={ref}
      defaultChecked={!!defaultValue || !!defaultChecked}
      {...rest}
    />
  );
}

CustomSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
};

CustomSwitch.defaultProps = {
  defaultChecked: false,
};
