import React from 'react';
import {
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Form } from '@unform/web';
import { useUsers } from '../../hooks/users';

import TextField from '~/components/Utils/TextField';
import history from '~/services/history';

import { useStyles } from './styles';
import { useResults } from '../../hooks/results';

function Users() {
  const formRef = React.useRef(null);
  const classes = useStyles();
  const { results, getUsers, query, setUsersQuery, putUserAdmin } = useUsers();
  const { setUser } = useResults();

  React.useEffect(() => {
    getUsers();
  }, [getUsers]);

  const handleChangePage = (_, newPage) => {
    setUsersQuery({ ...query, page: newPage });
  };

  const handleSubmit = React.useCallback(
    async (data) => {
      setUsersQuery({ ...query, ...data });
    },
    [query, setUsersQuery]
  );

  const handleChangeAdmin = async (id, is_admin) => {
    await putUserAdmin(id, is_admin);
  };

  const handleClickBack = () => {
    history.push('/');
  };

  const handleClickResults = (id) => {
    const user = results.rows.find((userFound) => userFound.id === id);
    setUser(user);
    history.push(`/user/${id}/results`);
  };

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClickBack}
        className={classes.btBack}
      >
        Voltar
      </Button>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Grid container className={classes.filterContainer}>
            <Grid item xs={12}>
              <Typography variant="body1">Filtros</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    name="nome"
                    variant="outlined"
                    label="Nome"
                    size="small"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    name="email"
                    variant="outlined"
                    label="E-mail"
                    size="small"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.gridBtFilter}>
              <Button variant="contained" color="primary" type="submit">
                Pesquisar
              </Button>
            </Grid>
          </Grid>
        </Form>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>Nome</TableCell>
              <TableCell align="right" className={classes.headerCell}>
                E-mail
              </TableCell>
              <TableCell align="right" className={classes.headerCell}>
                Administrador
              </TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {results.rows.map((user) => (
              <TableRow key={user.id}>
                <TableCell component="th" scope="row">
                  {user.nome}
                </TableCell>
                <TableCell align="right">{user.email}</TableCell>
                <TableCell align="right">
                  <Checkbox
                    defaultChecked={user.is_admin}
                    color="primary"
                    onChange={() => handleChangeAdmin(user.id, !user.is_admin)}
                  />
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleClickResults(user.id)}
                  >
                    Ver Resultados
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={3}
                count={results.count}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from - results.limit}-${to - results.limit} de ${
                    count !== -1 ? count : `more than ${to}`
                  }`
                }
                rowsPerPage={
                  results.count < results.limit ? results.count : results.limit
                }
                rowsPerPageOptions={[10]}
                page={results.page}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Users;
