import React, { createContext, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

const StorageContext = createContext({});

export const StorageProvider = ({ children }) => {
  const insert = useCallback((key, value) => {
    localStorage.setItem(key, value);
  }, []);

  const select = useCallback((key) => {
    const data = localStorage.getItem(key);
    return JSON.parse(data);
  }, []);

  const remove = useCallback((key) => {
    localStorage.removeItem(key);
  }, []);

  return (
    <StorageContext.Provider
      value={{
        insert,
        select,
        remove,
      }}
    >
      {children}
    </StorageContext.Provider>
  );
};

StorageProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export function useStorage() {
  const context = useContext(StorageContext);

  return context;
}
