import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '400px',
    padding: theme.spacing(2),
  },
  player: {
    display: 'flex',
    justifyContent: 'center',
  },
  next: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  title: {
    marginBottom: '15px',
    fontWeight: 'bold',
  },
  result: {
    fontWeight: 'bold',
  },
  cardParent: {
    padding: '15px',
  },
  card: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: '200px',
    padding: '0 10px',
  },
  property: {
    marginTop: '20px',
    fontWeight: 'bold',
  },
}));
