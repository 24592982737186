import React from 'react';
import { Button, Fade, makeStyles, Typography } from '@material-ui/core';

import history from '~/services/history';

import lavteve from '~/assets/labteve.png';
import liev from '~/assets/liev.png';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  divBt: {
    marginTop: 20,
  },
  text: {
    color: '#a6a6a6',
  },
  img: {
    width: 200,
    height: 200,
  },
  images: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    marginBottom: 20,
  },
}));

function Credits() {
  const classes = useStyles();
  const handleClickBack = () => history.push('/');
  return (
    <Fade in timeout={3000}>
      <div className={classes.root}>
        <div className={classes.images}>
          <img src={liev} alt="LIEV" className={classes.img} />
          <img src={lavteve} alt="LAVTEVE" className={classes.img} />
        </div>
        <div>
          <Typography variant="h5" align="justify" className={classes.text}>
            Este simulador foi desenvolvido pela parceria entre o Laboratório
            Integrado de Estudos da Voz (LIEV) e o Laboratório de Tecnologias
            para o Ensino Virtual e Estatística (LabTEVE) da Universidade
            Federal da Paraíba (UFPB).
          </Typography>
        </div>
        <div className={classes.divBt}>
          <Button variant="contained" color="primary" onClick={handleClickBack}>
            Voltar
          </Button>
        </div>
      </div>
    </Fade>
  );
}

export default Credits;
