import React, { createContext, useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';

// import api from '~/services/api';

const UtilsContext = createContext({});

export const UtilsProvider = ({ children }) => {
  const [attendanceStatus, setAttendanceStatus] = useState([]);
  const [attendanceTypes, setAttendanceTypes] = useState([]);
  const [darkMode, setDarkMode] = useState(false);

  const loadAttendanceStatus = useCallback(async () => {
    // const response = await api.get('/attendance-status');
    setAttendanceStatus([]);
  }, []);

  const loadAttendanceTypes = useCallback(async () => {
    // const response = await api.get('/attendance-type');
    setAttendanceTypes([]);
  }, []);

  const updateDarkMode = useCallback(async (dark) => {
    setDarkMode(dark);
  }, []);

  return (
    <UtilsContext.Provider
      value={{
        loadAttendanceStatus,
        loadAttendanceTypes,
        attendanceStatus,
        attendanceTypes,
        updateDarkMode,
        darkMode,
      }}
    >
      {children}
    </UtilsContext.Provider>
  );
};

UtilsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export function useUtils() {
  const context = useContext(UtilsContext);

  return context;
}
