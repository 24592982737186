import { makeStyles } from '@material-ui/core/styles';
import { blueGrey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    backgroundColor: blueGrey[900],
    backgroundImage: `linear-gradient(90deg, ${blueGrey[900]}, ${blueGrey[600]})`,
  },
  content: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
}));
