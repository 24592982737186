import React, { useRef, useEffect, useState } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';
import {
  InputLabel,
  Select,
  FormHelperText,
  FormControl,
} from '@material-ui/core';

export default function FormSelect({
  name,
  label,
  children,
  formControlProps,
  inputLabelProps,
  ...props
}) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [valueX, setValueX] = useState(defaultValue || undefined);

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: fieldName,
        ref: ref.current,
        path: 'value',
        clearValue: (valueRef) => {
          valueRef.clearValue();
          setValueX(undefined);
        },
      });
    }
  }, [ref.current, registerField]); //eslint-disable-line

  return (
    <FormControl error={!!error} {...formControlProps}>
      <InputLabel id={`${name}-label`} {...inputLabelProps}>
        {label}
      </InputLabel>
      <Select
        labelId={`${name}-label`}
        label={label}
        name={name}
        defaultValue={valueX}
        inputRef={ref}
        {...props}
      >
        {children}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  formControlProps: PropTypes.shape(),
  inputLabelProps: PropTypes.shape(),
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

FormSelect.defaultProps = {
  formControlProps: {},
  inputLabelProps: {},
};
