import React, { useRef, useCallback } from 'react';
import { Button, Link, Typography, Grid } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import TextField from '~/components/Utils/TextField';

import getValidationErrors from '~/utils/getValidationErrors';
import history from '~/services/history';
import { useAuth } from '~/hooks/auth';
import { useLoading } from '~/hooks/loading';

import { useStyles } from './styles';

export default function ForgotPassword() {
  const classes = useStyles();
  const formRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const { recoverPassword } = useAuth();
  const { showLoading, hideLoading } = useLoading();

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Precisamos de um email válido.')
            .required('Precisamos que informe o seu e-mail.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        showLoading();
        await recoverPassword(data);
        hideLoading();
        enqueueSnackbar('Acesso efetuado com sucesso', {
          variant: 'success',
        });
        history.push('/dashboard');
      } catch (err) {
        hideLoading();
        if (err.inner) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);

          return;
        }

        if (err.message === 'Network Error') {
          enqueueSnackbar(
            'Serviço temporareamente indisponível, tente novamente mais tarde.',
            { variant: 'info' }
          );

          return;
        }

        enqueueSnackbar('Usuário e/ou senha inválido(s)', { variant: 'error' });
      }
    },
    [enqueueSnackbar, recoverPassword, showLoading, hideLoading]
  );

  return (
    <div className={classes.paper}>
      <Grid
        container
        justifyContent="space-between"
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={8}>
          <Typography component="h1" variant="h5" className={classes.title}>
            Recuperar senha
          </Typography>
          <Typography
            component="p"
            variant="body2"
            className={classes.subtitle}
          >
            Informe seu e-mail para recuperar a senha
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.logo}>
          <Lock color="primary" fontSize="large" />
        </Grid>
      </Grid>
      <Form className={classes.form} ref={formRef} onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          fullWidth
          label="E-mail"
          name="email"
          autoComplete="email"
          color="primary"
          margin="normal"
          size="small"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          size="small"
        >
          Enviar
        </Button>
      </Form>
      <Link
        component="button"
        variant="body2"
        onClick={() => {
          history.push('/');
        }}
        color="inherit"
      >
        Entrar no sistema
      </Link>
    </div>
  );
}
