import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Initial from '~/pages/Initial';
import SignIn from '~/pages/SignIn';
import SignUp from '~/pages/SignUp';
import Guide from '~/pages/Guide';
import ForgotPassword from '~/pages/ForgotPassword';
import RecoverPassword from '~/pages/RecoverPassword';

import Dashboard from '~/pages/Dashboard';
import Adjustment from '~/pages/Adjustment';
import Presentation from '~/pages/Presentation';
import Concepts from '~/pages/Concepts';
import Classification from '~/pages/Classification';
import Training from '~/pages/Training';
import Final from '~/pages/FinalClassification';
import Report from '~/pages/Report';
import Credits from '~/pages/Credits';
import Users from '~/pages/Users';
import Results from '~/pages/Results';

import Error from '~/pages/Error';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Initial} />
      <Route path="/signin" exact component={SignIn} />
      <Route path="/signup" exact component={SignUp} />
      <Route path="/forgot" exact component={ForgotPassword} />
      <Route path="/guide" exact component={Guide} />
      <Route path="/recover/:token" exact component={RecoverPassword} />

      <Route path="/dashboard" exact component={Dashboard} isPrivate />
      <Route path="/adjustment" exact component={Adjustment} isPrivate />
      <Route
        path="/presentation/:id"
        exact
        component={Presentation}
        isPrivate
      />
      <Route path="/concepts/:id" exact component={Concepts} isPrivate />
      <Route
        path="/classification/:id"
        exact
        component={Classification}
        isPrivate
      />
      <Route path="/training/:id" exact component={Training} isPrivate />
      <Route
        path="/training/classification/:id"
        exact
        component={Classification}
        isPrivate
      />
      <Route path="/final/:id" exact component={Final} isPrivate />
      <Route path="/report/:id" exact component={Report} isPrivate />
      <Route path="/credits" exact component={Credits} />
      <Route path="/users" exact component={Users} isPrivate />
      <Route path="/user/:id/results" exact component={Results} isPrivate />

      <Route path="/" component={Error} />
    </Switch>
  );
}
