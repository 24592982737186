import React from 'react';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import brLocale from 'date-fns/locale/pt-BR';

import { AuthProvider } from '~/hooks/auth';
import { LoadingProvider } from '~/hooks/loading';
import { UtilsProvider } from '~/hooks/utils';
import { StorageProvider } from '~/hooks/storage';
import { DatabaseProvider } from '~/hooks/database';
import { ClassificationProvider } from '~/hooks/classification';
import { UsersProvider } from './users';
import { ResultsProvider } from './results';

export default function AppProvider({ children }) {
  return (
    <StorageProvider>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
        <LoadingProvider>
          <UtilsProvider>
            <AuthProvider>
              <DatabaseProvider>
                <ClassificationProvider>
                  <UsersProvider>
                    <ResultsProvider>{children}</ResultsProvider>
                  </UsersProvider>
                </ClassificationProvider>
              </DatabaseProvider>
            </AuthProvider>
          </UtilsProvider>
        </LoadingProvider>
      </MuiPickersUtilsProvider>
    </StorageProvider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
