import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Typography, Grid, Fade, Paper, Button } from '@material-ui/core';

import { useStyles } from './styles';
import { useDatabase } from '~/hooks/database';
import { useAuth } from '~/hooks/auth';
import { useClassification } from '~/hooks/classification';

import history from '~/services/history';

export default function Presentation() {
  const { id } = useParams();
  const { updateUser } = useAuth();
  const classes = useStyles();
  const { select } = useDatabase();
  const [loaded, setLoaded] = useState(false);
  const [data] = useState(select('fases', id));
  const { startPhase, fase } = useClassification();
  const { search } = useLocation();
  const reload = new URLSearchParams(search).get('reload');

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded]); // eslint-disable-line

  useEffect(() => {
    if (!fase.id || fase.id !== Number(id) || Boolean(reload)) {
      startPhase(Number(id));
    }
  }, []); // eslint-disable-line

  const handleNext = async () => {
    await updateUser({
      proximo: `${data.apresentacao.proximo}/${id}`,
      fase_atual: Number(id),
    });
    history.push(`${data.apresentacao.proximo}/${id}`);
  };

  return (
    <Paper>
      <Fade in={loaded} timeout={1000}>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="h4">{data.apresentacao.titulo}</Typography>
          </Grid>
          <Grid item xs={12}>
            {data.apresentacao.descricoes.map((descricao) => (
              <Typography variant="h6" align="center" key={descricao}>
                {descricao}
              </Typography>
            ))}
          </Grid>
          <Grid item xs={12} className={classes.next}>
            <Button variant="contained" color="primary" onClick={handleNext}>
              AVANÇAR
            </Button>
          </Grid>
        </Grid>
      </Fade>
    </Paper>
  );
}
