import React from 'react';
import { Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import history from '~/services/history';
import AppProvider from '~/hooks';

import Routes from './routes';
import GlobalStyle from '~/styles/global';

function App() {
  return (
    <AppProvider>
      <SnackbarProvider dense preventDuplicate>
        <Router history={history}>
          <Routes />
          <GlobalStyle />
        </Router>
      </SnackbarProvider>
    </AppProvider>
  );
}

export default App;
